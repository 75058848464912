
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from '@material-ui/core'
import { generateTimestamp } from '../functions'
import { Link } from 'react-router'
import { setSidebar, getBadge } from '../actions'

const mapStateToProps = ({ badges }, ownProps) => {
  let userBadge = ownProps.widget.userBadge

  return {
    badge: userBadge && badges.badgeData.find(badge => badge.id === userBadge.relationships.field_badge.data.id)
  }
}

const mapDispatchToProps = {
  setSidebar,
  getBadge
}

class WidgetBadge extends Component {

  state = {
    message: ''
  }

  componentDidMount = async () => {
    await this.props.getBadge(this.props.widget.userBadge.relationships.field_badge.data.id)
    this.setState({ message: this.generateMessage() })
  }

  handleClick = () => {
    this.props.setSidebar('achievements', 'badgesEarned')
  }

  generateMessage = () => {
    let badgeName = this.props.badge.attributes.name
    let timestamp = generateTimestamp(this.props.widget.userBadge.attributes.created).toLowerCase()

    // Randomly choose a congratulatory message to keep things fun and fresh
    let index = Math.floor(Math.random() * Math.floor(4))
    let options = [
      <>Woohoo! You earned the <strong>{badgeName}</strong> badge {timestamp}. What’s next?</>,
      <>Nice! You snagged the <strong>{badgeName}</strong> badge {timestamp}. Keep going!</>,
      <>Oh yeah! The <strong>{badgeName}</strong> badge is yours! What’s next?</>,
      <>Sweet! You earned the <strong>{badgeName}</strong> badge {timestamp}. Keep going!</>,
      <>Great job! You got the <strong>{badgeName}</strong> badge {timestamp}. What’s next?</>,
    ]

    return options[index]
  }

  render() {

    const { badge } = this.props

    let badgeImageURL
    if (badge) {
      badgeImageURL = badge.image ? process.env.REACT_APP_API_URL + badge.image.attributes.uri.url : null
    }

    return (
      <Card className="widget badgeEarned" style={{ animationDelay: this.props.animationDelay }}>
        <Link onClick={this.handleClick} to="/achievements">
          <div className="widgetLabel success">{this.props.widget.label}</div>
          <div className='widgetIcon icon badge' />
          <div className="flexRow">
            <div className="imageWrapper">
              {
                badge &&
                <>
                  <img className="badgeImage" alt={badge.attributes.name} src={badgeImageURL} />
                  <span className="icon check" />
                </>
              }
            </div>
            <div className="badgeInfo">
              {this.state.message && <p>{this.state.message}</p>}
            </div>
          </div>
          <footer>
            {/* <div className="certificateInfo">
            <Button className="button small">
              <span className="icon certificate"></span>
              View Certificate
            </Button>
          </div> */}
          </footer>
        </Link>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBadge);