
import React, { Component } from 'react'

class Loading extends Component {

  render() {
    return (
      <div className="loadingWrapper">
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
        <span>{this.props.message && this.props.message}</span>
      </div>
    )
  }
}

export default Loading;