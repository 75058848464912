import React from 'react'
import classNames from 'classnames'
import { setSidebar } from '../actions'
import { connect } from 'react-redux'
import { getAssessments } from 'common/actions/course'
import { markNotificationAsSeen } from '../actions'
import { Link } from 'react-router'

const mapStateToProps = ({ courses, assessments, badges }) => {

  return {
    assessments,
    courses: courses.courseData,
    badges: badges.badgeData
  }
}

const mapDispatchToProps = {
  setSidebar,
  getAssessments,
  markNotificationAsSeen
}

class NotificationPopup extends React.Component {
  state = {
    open: true,
  }

  prepareContent = (notification) => {
    let content
    if (notification.relationships.field_user_badge.data) {
      content = this.generateBadgeContent(notification)
    }
    if (notification.relationships.field_assessment.data) {
      if (notification.attributes.field_notification_type === 'assessment_feedback') {
        content = <>Feedback available for <strong>{notification.course.attributes.name}</strong>.</>
      }
      else {
        content = this.generateCourseContent(notification)
      }
    }
    if (notification.learningPath) {
      content = this.generateLearningPathContent(notification)
    }

    this.setState({
      open: true,
      activeNotification: true,
      content: content
    })

    setTimeout(() => {
      this.setState({ open: false })
    }, 3500)

    setTimeout(() => {
      this.setState({ activeNotification: false })
      this.props.markNotificationAsSeen(notification)
    }, 5000)
  }

  componentDidMount = () => {
    this.prepareContent(this.props.notification)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.notification.id !== this.props.notification.id) {
      this.prepareContent(this.props.notification)
    }
  }

  generateCourseContent = (notification) => {
    let courseName = notification.course && notification.course.attributes.name
    let index = Math.floor(Math.random() * Math.floor(3))
    let options = [
      <>Woohoo! You completed <strong>{courseName}</strong>. What’s next?</>,
      <>Oh yeah! <strong>{courseName}</strong> is done! Let's keep going!</>,
      <>Sweet! You rocked the challenge for <strong>{courseName}</strong>. Keep it up!</>,
      <>Great job! You successfully finished <strong>{courseName}</strong>. What’s next?</>
    ]

    return options[index]
  }

  generateBadgeContent = (notification) => {
    let badgeName = notification.badge && notification.badge.attributes.name
    let index = Math.floor(Math.random() * Math.floor(4))

    let options = [
      <>Woohoo! You earned the <strong>{badgeName}</strong> badge. What’s next?</>,
      <>Nice! You snagged the <strong>{badgeName}</strong> badge. Keep going!</>,
      <>Oh yeah! The <strong>{badgeName}</strong> badge is yours! What’s next?</>,
      <>Sweet! You earned the <strong>{badgeName}</strong> badge. Keep going!</>,
      <>Great job! You got the <strong>{badgeName}</strong> badge. What’s next?</>
    ]

    return options[index]
  }

  generateLearningPathContent = (notification) => {
    let pathName = notification.learningPath && notification.learningPath.attributes.name
    let index = Math.floor(Math.random() * Math.floor(3))
    let options = [
      <>Woohoo! You finished <strong>{pathName}</strong>. What’s next?</>,
      <>Nice! You finished all the courses in <strong>{pathName}</strong>!</>,
      <>Sweet! You completed <strong>{pathName}</strong>. Keep going!</>,
      <>Great job! You made it through <strong>{pathName}</strong>. What’s next?</>,
    ]

    return options[index]
  }

  handleClick = () => {
    const { notification } = this.props
    this.setState({ open: false })
    this.props.markNotificationAsSeen(notification)
    let type = notification.attributes.field_notification_type

    if (type === 'ulp_complete') {
      this.props.setSidebar('achievements', 'learningPathsCompleted')
    }
    if (type === 'assessment_complete') {
      this.props.setSidebar('achievements', 'completed')
    }
    if (type === 'user_badge_created') {
      this.props.setSidebar('achievements', 'badgesEarned')
    }

  }

  render() {
    const { content, activeNotification } = this.state
    const { notification } = this.props

    let type = notification.attributes.field_notification_type, path

    if (type === 'assessment_feedback') {
      path = '/course/' + notification.course.attributes.drupal_internal__id
    }
    if (type === 'ulp_complete' || type === 'assessment_complete' || type === 'user_badge_created') {
      path = '/achievements'
    }

    return (
      <>
        {
          activeNotification && !notification.attributes.field_notification_seen &&
          <Link to={path} onClick={this.handleClick} className={classNames('notification-popup', !this.state.open && 'slideOut')}>
            <div className="inner">
              {
                notification &&
                <>
                  <div className="flexRow">
                    {
                      notification.badge &&
                      <div className="icon badge" />
                    }
                    {
                      notification.learningPath &&
                      <div className="icon learningPath" />
                    }
                    {
                      notification.course &&
                      <div className={classNames('icon', notification.attributes.field_notification_type)} />
                    }
                    <p>{content}</p>
                  </div>
                </>
              }
            </div>
          </Link>
        }
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup)