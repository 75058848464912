import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { Menu, Button } from '@material-ui/core'

import { setNotificationsFetched } from 'common/actions/user'
import { getAssessment } from 'common/actions/course'

import { getNotifications, deleteNotification, setSidebar, getUserLearningPath, getBadge, getUserBadge } from '../actions'
import NotificationPopup from './NotificationPopup'
import NotificationMenuItem from './NotificationMenuItem'

import '../styles/navbar.scss'

const mapStateToProps = ({ courses, assessments, notifications, session, badges, learningPaths }) => {

  let notificationData = [...notifications]
  notificationData.reverse()

  return {
    notifications: notificationData,
    session,
    courses: courses.courseData,
    learningPaths: learningPaths.paths,
    assessments,
    badges: badges.badgeData
  }
}

const mapDispatchToProps = {
  getNotifications,
  getUserLearningPath,
  getAssessment,
  getUserBadge,
  setSidebar,
  deleteNotification,
  getBadge,
  setNotificationsFetched
}

class NotificationsMenu extends Component {

  state = {
    anchorEl: null,
    hasOpened: false,
    seen: [],
    removed: []
  }

  componentDidUpdate = (prevProps) => {

    const { session, badges } = this.props
    // If we have new notifications, fetch what we need
    if (this.props.notifications.length > prevProps.notifications.length) {
      this.setState({ allRemoved: false })
      this.props.notifications.forEach((notification) => {
        let type = notification.attributes.field_notification_type

        if (type === 'ulp_complete' && !session.fetched.userLearningPaths) {
          let existingUserLearningPath = session.userLearningPaths.find(path => path.id === notification.relationships.field_user_learning_path.data.id)
          !existingUserLearningPath && this.props.getUserLearningPath(notification.relationships.field_user_learning_path.data.id)
        }

        if (type === 'assessment_feedback' || type === 'assessment_complete') {
          this.props.getAssessment(notification.relationships.field_assessment.data.id)
        }

        if (type === 'user_badge_created') {
          this.props.getUserBadge(notification.relationships.field_user_badge.data.id)
        }
      })
    }
    let popUpNotification = this.props.notifications[0]
    if (popUpNotification && popUpNotification.attributes.field_notification_type === 'user_badge_created') {
      let userBadge = session.userBadges.find(userBadge => userBadge.id === this.props.notifications[0].relationships.field_user_badge.data.id)
      let badge = userBadge && badges.find(badge => badge.id === userBadge.relationships.field_badge.data.id)
      if (userBadge && !badge) {
        this.props.getBadge(userBadge.relationships.field_badge.data.id)
      }
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, hasOpened: true })
    this.props.fetchNotifications()
  }

  handleClose = (badge) => {
    this.setState({ anchorEl: null })

    // Mark notifications as seen
    this.props.setNotificationsFetched(this.props.session.user)

    this.props.notifications.forEach((notification) => {
      setTimeout(() => {
        let seen = [...this.state.seen]
        seen.push(notification.id)
        this.setState({ seen })
      }, 750)
    })
    if (badge) {
      this.props.setSidebar('achievements', 'badgesEarned')
    }
  }

  delete = (notification) => {
    this.props.deleteNotification(notification)
    let removed = [...this.state.removed]
    removed.push(notification.id)
    if (removed.length === this.props.notifications.length) {
      this.setState({ anchorEl: null })
    }
    this.setState({ removed })
  }

  deleteAll = () => {
    let removed = [...this.state.removed]
    this.setState({ anchorEl: null, allRemoved: true })

    setTimeout(() => {
      this.props.notifications.forEach((notification) => {
        removed.push(notification.id)
        this.props.deleteNotification(notification)
      })
      this.setState({ removed })
    }, 1200)
  }

  render() {
    const { notifications, assessments, learningPaths, badges, session } = this.props
    const { anchorEl, removed, seen } = this.state

    let lastFetched = session.user.attributes.field_notifications_last_fetched

    let unseenCount = notifications.filter(notification =>
      !lastFetched || moment(lastFetched).isBefore(notification.attributes.created)
    ).length

    let popUpNotification = { ...notifications[0] }
    let type = popUpNotification.attributes && popUpNotification.attributes.field_notification_type

    if (type === 'assessment_complete' || type === 'assessment_feedback') {
      let assessment = assessments.all.find(assessment => assessment.id === popUpNotification.relationships.field_assessment.data.id)
      popUpNotification.course = assessment && this.props.courses.find(course => course.id === assessment.relationships.field_course.data.id)
    }
    if (type === 'user_badge_created') {
      let userBadge = session.userBadges.find(userBadge => userBadge.id === popUpNotification.relationships.field_user_badge.data.id)
      popUpNotification.badge = userBadge && badges.find(badge => badge.id === userBadge.relationships.field_badge.data.id)
    }
    if (type === 'ulp_complete') {
      let userLearningPath = session.userLearningPaths.find(path => path.id === popUpNotification.relationships.field_user_learning_path.data.id)
      popUpNotification.learningPath = userLearningPath && learningPaths.find(path => path.id === userLearningPath.relationships.field_learning_path.data.id)
    }

    let isEmpty = !this.props.notifications.length || this.state.allRemoved
    let notificationsReady = unseenCount > 0 && notifications.length && (popUpNotification.course || popUpNotification.learningPath || popUpNotification.badge)

    return (
      <div>
        {
          notificationsReady &&
          <NotificationPopup
            session={session}
            notification={popUpNotification}
          />
        }

        <div onClick={this.handleClick} className={classNames('notifications', notificationsReady && 'swing')}>
          {
            unseenCount > 0 &&
            <div className={classNames('count', this.state.hasOpened && 'fade')}>
              {unseenCount}
            </div>
          }
        </div>

        <Menu id="notification-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <ul className={classNames('notifications-list', { 'empty': isEmpty })}>
            {
              notifications.map((notification) => {
                return (
                  <NotificationMenuItem
                    deleteNotification={() => { this.delete(notification) }}
                    removed={removed}
                    seen={seen}
                    handleClose={this.handleClose}
                    key={notification.id}
                    notification={notification} />
                )
              })
            }
          </ul>
          {
            isEmpty ?
              <div className="message-empty">You have no new notifications.</div>
              :
              <footer>
                <Button onClick={this.deleteAll} className="button small">Clear All Notifications</Button>
              </footer>
          }
        </Menu>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu);
