import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../styles/cards.scss'

import Sidebar from './Sidebar'
import InProgress from './InProgress'

const mapStateToProps = (state, ownProps) => {
  return {
    sidebar: state.sidebar
  }
}

class InProgressLayout extends Component {
  render() {
    const page = this.props.sidebar.pages.inProgress

    return (
      <div className="layout in-progress">
        <Sidebar currentPage="inProgress" activeItem={page.activeItem} items={page.items}/> 
        <InProgress currentPage={page.activeItem}/>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InProgressLayout);
