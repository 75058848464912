import React, { Component } from 'react'
import nl2br from 'nl2br'
import sanitizeHtml from 'sanitize-html'

class SanitizedHTML extends Component {

  render() {
    return (
      <p dangerouslySetInnerHTML={{ __html: nl2br(sanitizeHtml(this.props.html)) }} />
    )
  }
}

export default SanitizedHTML