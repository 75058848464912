import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import session from 'common/reducers/session'
import moment from 'moment'

const navbar = (state = {}, action) => {

  switch (action.type) {
    case 'CHANGE_NAVBAR':
      return { ...state, activeItem: action.activeItem }
    default:
      return state;
  }
}

const extension = (state = {}, action) => {
  switch (action.type) {
    case 'GET_EXTENSION_VERSION':
      return { ...state, version: action.version }
    default:
      return state;
  }
}

const courses = (state = { courseData: [] }, action) => {
  switch (action.type) {
    case 'GET_COURSES':
      let courseData = [...state.courseData]
      let fetched

      // Don't merge in duplicates
      action.data && action.data.forEach((course) => {
        if (!(courseData.find(existing => existing.id === course.id))) {
          courseData.push(course)
        }
      })

      if (action.fetched) {
        fetched = action.fetched
      }
      else {
        fetched = state.fetched
      }

      return { ...state, fetched: fetched, courseData }
    default:
      return state;
  }
}

const learningPaths = (state = { types: [], paths: [], fetched: false }, action) => {
  switch (action.type) {

    case 'GET_LEARNING_PATHS':

      let paths = [...state.paths]
      let types = [...state.types]
      let newPaths = action.data

      // Match learning paths with their images    
      newPaths.forEach((path) => {
        if (path.relationships.field_image.data) {
          path.image = action.included.find(image => image.id === path.relationships.field_image.data.id)
        }
      })

      newPaths.forEach((path) => {
        if (path.relationships.field_type.data) {
          let type = action.included.find(type => type.id === path.relationships.field_type.data.id)
          path.type = type.attributes.name
          let existing = types.find(existingType => existingType.id === type.id)
          // Don't replace existing types
          if (!existing) {
            types.push(type)
          }

        }
      })

      // Don't include learning paths unless they have all of this information
      newPaths.forEach((path) => {
        let description = path.attributes.field_description
        let image = path.image
        let courses = path.relationships.field_courses.data.length
        let type = path.type
        let existing = paths.find(existingPath => existingPath.id === path.id)
        // Don't replace existing paths
        if (!existing && description && image && courses && type) {
          paths.push(path)
        }
      })

      return { types: Array.from(new Set(types)), paths, fetched: action.fetched }
    default:
      return state
  }
}

const badges = (state = { badgeData: [], fetched: false }, action) => {
  switch (action.type) {
    case 'GET_BADGE':
      let badges = [...state.badgeData]
      let badge = action.data
      badge.image = action.image

      if (!(badges.find(existing => existing.id === badge.id))) {
        badges.push(badge)
      }

      return { badgeData: badges }

    case 'GET_BADGES':

      let badgeImages = action.images
      let badgeData = [...state.badgeData]

      action.badges.forEach((badge) => {
        if (!(badgeData.find(existing => existing.id === badge.id))) {
          badgeData.push(badge)
        }
      })

      badges = badgeData.map((badge) => {
        if (badge.relationships.field_badge_image.data) {
          badge.image = badgeImages.find(image => image.id === badge.relationships.field_badge_image.data.id)
        }
        return badge
      })

      return { badgeData: badges, fetched: action.fetched }
    default:
      return state;
  }
}

const sidebar = (state = {}, action) => {
  switch (action.type) {
    case 'COLLAPSE':
      return { ...state, isCollapsed: true }
    case 'SHOW':
      return { ...state, isCollapsed: false }
    case 'SELECT_ITEM':
      let sidebar = { ...state }
      sidebar.pages[action.page].activeItem = action.activeItem
      return sidebar
    case 'EXPAND_MOBILE_FILTERS':
      return { ...state, mobileFiltersExpanded: action.data }
    case 'UPDATE_EXPLORE_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'explore').filters = action.data
      return sidebar
    case 'UPDATE_LEARNING_PATH_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'learningPaths').filters = action.data
      return sidebar
    case 'UPDATE_BADGE_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'badges').filters = action.data
      return sidebar
    case 'UPDATE_BADGES_EARNED_FILTERS':
      sidebar = { ...state }
      sidebar.pages.achievements.items.find(item => item.id === 'badgesEarned').filters = action.data
      return sidebar
    case 'UPDATE_COMPLETED_FILTERS':
      sidebar = { ...state }
      sidebar.pages.achievements.items.find(item => item.id === 'completed').filters = action.data
      return sidebar
    default:
      return state;
  }
}

const assessments = (state = { all: [], saved_for_later: [], in_progress: [], submitted: [], completed: [] }, action) => {
  switch (action.type) {
    case 'START_FROM_SAVED':
      let assessments = { ...state }

      assessments.saved_for_later = assessments.saved_for_later.filter(assessment => assessment.id !== action.assessment.id)
      assessments.in_progress.push(action.assessment)

      return assessments

    case 'CREATE_ASSESSMENT':
      assessments = { ...state }

      assessments[action.status].push(action.data)
      assessments.all.push(action.data)

      return assessments

    case 'DELETE_ASSESSMENT':
      assessments = { ...state }
      let inProgress = assessments.in_progress.filter(assessment => assessment.id !== action.data.id)
      let all = assessments.all.filter(assessment => assessment.id !== action.data.id)
      assessments.all = all
      assessments.in_progress = inProgress

      return assessments

    case 'GET_ASSESSMENTS':

      assessments = { ...state }
      assessments.fetched = action.fetched

      if (action.status) {
        if (!action.fetched) {
          assessments[action.status] = action.data
        }
        else {
          action.data.forEach((assessment) => {
            assessments[action.status].push(assessment)
          })
        }
      }
      else {
        // Don't merge in duplicates
        action.data.forEach((assessment) => {
          let status
          if (assessment.attributes.field_status === 'grading_in_progress') {
            status = 'in_progress'
          }
          else {
            status = assessment.attributes.field_status
          }

          if (!(assessments[status].find(existing => existing.id === assessment.id))) {
            assessments[status].push(assessment)
          }
          if (!(assessments.all.find(existing => existing.id === assessment.id))) {
            assessments.all.push(assessment)
          }
        })

      }
      assessments.all = []
      assessments.all.push(...assessments.saved_for_later, ...assessments.in_progress, ...assessments.submitted, ...assessments.completed)

      return assessments

    default:
      return state;
  }
}

const jobTitles = (state = [], action) => {
  switch (action.type) {
    case 'GET_JOB_TITLES':
      return action.data.data
    default:
      return state;
  }
}
const categories = (state = [], action) => {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return action.data.data
    default:
      return state;
  }
}

const announcements = (state = { data: [], media: [], surveys: [] }, action) => {
  switch (action.type) {
    case 'GET_ANNOUNCEMENTS':

      // Filter out expired announcements
      let announcements = action.data.filter(announcement => moment().isBefore(announcement.attributes.field_expiration_date))

      let images = action.included && action.included.filter(included => included.type === "file--image")
      let videos = action.included && action.included.filter(included => included.type === "file--video")
      let surveys = action.included && action.included.filter(included => included.type === "survey_entity_type--survey_entity_type")
      let types = action.included && action.included.filter(included => included.type === "taxonomy_term--announcement_type")

      return { data: announcements, images: images, videos: videos, surveys: surveys, types: types }
    default:
      return state;
  }
}

const viewStyle = (state = 'grid', action) => {
  switch (action.type) {
    case 'SET_VIEW_STYLE':
      return action.data
    default:
      return state
  }
}

const productTour = (state = { isStarted: false }, action) => {
  switch (action.type) {
    case 'START_PRODUCT_TOUR':
      return { ...state, isStarted: true, trigger: action.trigger }
    case 'END_PRODUCT_TOUR':
      return { isStarted: false, trigger: action.trigger, hasStarted: true }
    default:
      return state
  }
}

const onboarding = (state = { started: false }, action) => {
  switch (action.type) {
    case 'START_ONBOARDING':
      state = { ...state, started: true }
      return state
    case 'END_ONBOARDING':
      state = { ...state, started: false }
      return state
    default:
      return state
  }
}

const notifications = (state = [], action) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      state = action.data
      return state
    default:
      return state
  }
}

const reducers = combineReducers({
  session,
  extension,
  courses,
  learningPaths,
  badges,
  jobTitles,
  categories,
  assessments,
  navbar,
  sidebar,
  announcements,
  viewStyle,
  productTour,
  onboarding,
  notifications,
  routing: routerReducer,
})

export default reducers
