import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import '../styles/sidebar.scss'
import { collapseSidebar, setSidebar } from '../actions'
import ExploreFilters from './ExploreFilters'
import BadgeFilters from './BadgeFilters'
import BadgeFiltersEarned from './BadgeFiltersEarned'
import CoursesCompletedFilters from './CoursesCompletedFilters'
import LearningPathFilters from './LearningPathFilters'
import { isMobile, isMobileOnly } from 'react-device-detect'
import classNames from 'classnames'

const mapStateToProps = ({ sidebar }, ownProps) => {
  return {
    sidebar
  }
}

const mapDispatchToProps = {
  collapseSidebar,
  setSidebar
}

class Sidebar extends Component {

  state = {
    showCollapse: false,
    peek: false,
  }

  peek = (peek) => {
    if (!isMobile) {
      this.setState({ peek })
    }
  }

  render() {
    const { sidebar, currentPage, activeItem } = this.props

    let isCollapsed = this.state.peek ? false : sidebar.isCollapsed

    return (
      <div onMouseEnter={() => this.setState({ showCollapse: true })} onMouseLeave={() => this.setState({ showCollapse: false })}>
        <div data-collapsed={sidebar.isCollapsed} data-show={this.state.showCollapse} className={classNames('collapse', this.state.peek && 'peek')} onClick={() => this.props.collapseSidebar(sidebar.isCollapsed)}>
          <span className="icon" />
        </div>
        <div onMouseEnter={() => { this.peek(true) }} onMouseLeave={() => { this.peek(false) }} id="sidebar" data-peek={this.state.peek} data-collapsed={isCollapsed}>
          {
            activeItem && 
            <ul>
              {
                sidebar.pages[currentPage].items.map((item) => {
                  return (
                    <li id={'tour-sidebar-' + item.title.replace(' ','-').toLowerCase()} key={item.id}>
                      <Button onClick={() => this.props.setSidebar(currentPage, item.id)} className={sidebar.pages[currentPage].activeItem === item.id ? 'active' : ''}>
                        <span id={item.id} className="icon" />
                        <span className="title">{item.title}</span>
                      </Button>
                    </li>
                  )
                })
              }
            </ul>
          }
          <div id="tour-sidebar-filters">
            {
              !isMobileOnly && activeItem && sidebar.pages[currentPage].activeItem === 'explore' &&
              <ExploreFilters />
            }
            {
              !isMobileOnly && activeItem && sidebar.pages[currentPage].activeItem === 'badges' &&
              <BadgeFilters />
            }
            {
              !isMobileOnly && activeItem && sidebar.pages[currentPage].activeItem === 'badgesEarned' &&
              <BadgeFiltersEarned />
            }
            {
              !isMobileOnly && activeItem && sidebar.pages[currentPage].activeItem === 'completed' &&
              <CoursesCompletedFilters />
            }
            {
              !isMobileOnly && activeItem && sidebar.pages[currentPage].activeItem === 'learningPaths' &&
              <LearningPathFilters />
            }

            { currentPage === 'course' && <>{this.props.content}</> }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
