import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'

import { changeNavbar, getAssessmentsByStatus, deleteAssessment } from '../actions'
import { getCourses, getAssessments } from 'common/actions/course'

import ViewToggle from './ViewToggle'
import CourseCard from './CourseCard'
import CourseListItem from './CourseListItem'
import Loading from './Loading'

import _ from 'lodash'

const mapStateToProps = ({ assessments, session, courses, categories, viewStyle }, ownProps) => {

  let currentAssessments
  if (ownProps.currentPage === 'feedback') {
    currentAssessments = assessments.all.filter(
      assessment => assessment.attributes.field_status === 'in_progress'
    ).filter(assessment => assessment.attributes.field_comments)  
  }
  else {
    currentAssessments = assessments[ownProps.currentPage].filter(assessment => !assessment.attributes.field_comments)  
  }
 
  return {
    session: session,
    courseData: courses.courseData,
    courses: courses,
    categories,
    currentAssessments: _.orderBy(currentAssessments, 'attributes.changed', 'desc'),
    viewStyle: viewStyle
  }
}

const mapDispatchToProps = {
  deleteAssessment,
  getAssessments,
  getCourses,
  changeNavbar,
  getAssessmentsByStatus
}

class InProgress extends Component {

  state = {
    fetched: false
  }
  
  async componentDidMount() {
    this.props.changeNavbar('in-progress')
    await this.props.getAssessmentsByStatus(this.props.currentPage)
    this.setState({ fetched: true })
  }

  async componentDidUpdate(prevProps) {
    // When the page updates, get the current status' assessments
    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({ fetched: false })
      await this.props.getAssessmentsByStatus(this.props.currentPage === 'feedback' ? 'in_progress' : this.props.currentPage)
      this.setState({ fetched: true })
    }
  }

  removeAssessment = (id) => {
    this.props.deleteAssessment(id, this.props.session)
  }

  render() {
    const { currentPage, currentAssessments, courseData, categories, viewStyle } = this.props
    const { fetched } = this.state

    let content, card
    let statusText

    switch(currentPage) {
      case 'saved_for_later':
        statusText = <>You don't have any micro-courses saved. Click <strong>Save for Later</strong> when viewing a course page to pin it here.</>
        break;
      case 'feedback':
        statusText = <>No feedback yet. Submit a challenge!</>
        break;
      case 'submitted':
        statusText = <>You haven't submitted any challenges yet. Let's go!</>
        break;
      default:
        statusText = <>You don't have any outstanding challenges — time to get started!</>
    }

    if (currentAssessments) {

      content = <>
        {
          currentAssessments.length > 0 && currentAssessments.map((assessment, index) => {
            let targetCourse = courseData.find((course) => {
              return course.id === assessment.relationships.field_course.data.id
            })

            if (targetCourse) {
              let category = categories.find(function(cat) {
                return cat.id === targetCourse.relationships.field_category.data.id
              })

              let secondaryCategories = targetCourse.relationships.field_secondary_category.data.map((secondaryCategory) => {
                return categories.find(cat => cat.id === secondaryCategory.id)
              })  
  
              card = <CourseCard
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                course={targetCourse}
                assessment={assessment}
                category={category}
                secondaryCategories={secondaryCategories}
                status={assessment.attributes.field_status}
                currentPage={currentPage}
                removeAssessment={this.removeAssessment}
              />
        
              if (viewStyle === 'list') {
                card = <CourseListItem
                  animationDelay={index * .05 + 's'}
                  key={assessment.id}
                  course={targetCourse}
                  assessment={assessment}
                  category={category}
                  secondaryCategories={secondaryCategories}
                  status={assessment.attributes.field_status}
                  currentPage={currentPage}
                  removeAssessment={this.removeAssessment}
                />
              }
            }
            return card
          })
        }
      </>
    }

    return (
      <div className="page in-progress">

        {
          fetched ?
          <>
            <ViewToggle />
            {
              currentAssessments.length > 0 && currentPage === 'submitted' &&
              <p className="statusText submitted">
                Please allow 24-48 hours for challenge submissions to be reviewed.
              </p>
            }
            <>
              {
                viewStyle === 'grid' ?
                <div className="course-library course-grid">
                  <Grid container spacing={3}>
                    {content}
                  </Grid>
                </div>
                :
                <ul className="course-library course-list">
                  {content}
                </ul>
              }
            </>
            {
              !currentAssessments.length &&
              <p className="statusText">
                {statusText}
              </p>
            }
          </>
          :
          <Loading />
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InProgress);