
import React, { Component } from 'react'
import { Card, Button } from '@material-ui/core'

class WidgetSocial extends Component {

  render() {

    return (
      <Card className="widget social" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel">Social</div>

        <p>Connect with us and get the latest news from MobileMind!</p>

        <div className="flexRow">
          <div className="logo circle" />
          <div className="links">
            <a target={"_blank"} href="https://twitter.com/mobilemindedu">
              <Button className="button small">
                <span className='icon twitter' /> Twitter
              </Button>
            </a>
            <a target={"_blank"} href="https://www.facebook.com/mobilemindedu/">
              <Button className="button small">
                <span className='icon facebook' /> Facebook
              </Button>
            </a>
            <a target={"_blank"} href="https://linkedin.com/company/mobilemind-/">
              <Button className="button small">
                <span className='icon linkedIn' /> LinkedIn
              </Button>
            </a>

          </div>
        </div>
      </Card>
    )
  }
}

export default WidgetSocial;