import React, { Component } from 'react'
import { connect } from 'react-redux'

import CourseCard from './CourseCard'
import CourseListItem from './CourseListItem'
import ViewToggle from './ViewToggle'
import { Grid } from '@material-ui/core'
import { isMobileOnly, isMobile } from 'react-device-detect'
import _ from 'lodash'
import classNames from 'classnames'
import ExploreFilters from './ExploreFilters'
import Loading from './Loading'

import { getAssessments, getCourses } from 'common/actions/course'
import { getBadges } from '../actions'
import '../styles/cards.scss'
import { isCourseCategoryExcluded } from '../functions'

const mapStateToProps = ({ sidebar, session, assessments, categories, learningPaths, badges, viewStyle, courses }, ownProps) => {

  let sortedCategories = _.sortBy(categories, (category) => {
    return category.attributes.name
  })

  let filters = sidebar.pages.learn.items.find(item => item.id === 'explore').filters

  let activeLearningPath = filters.learningPath && learningPaths.paths.find(path => path.id === filters.learningPath)

  return {
    session: session,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: filters,
    activeLearningPath: activeLearningPath,
    assessments: assessments.all,
    assessmentsFetched: assessments.fetched,
    categories: sortedCategories,
    learningPaths: learningPaths.paths,
    badges: badges.badgeData,
    courses: courses.courseData,
    coursesFetched: courses.fetched,
    viewStyle: viewStyle
  }

}

const mapDispatchToProps = {
  getAssessments,
  getBadges,
  getCourses
}

class ExploreLayout extends Component {

  componentDidMount() {
    if (!this.props.badges.fetched) {
      this.props.getBadges()
    }
    // This is the only time we will get all of these at once
    this.props.getAssessments()
    this.props.getCourses()
  }

  filterByQuery = (course) => {
    let { filters } = this.props
    if (course.attributes.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) > -1) {
      return course
    }
  }

  filterByRemaining = (course) => {
    let { filters } = this.props

    if (!filters.remainingOnly) {
      return course
    }
    else {
      let assessment = this.props.assessments.find(assessment => assessment.relationships.field_course.data.id === course.id)
      if (!assessment) {
        return course
      }
    }
  }

  filterByCategory = (course) => {
    let { filters } = this.props
    let hasCategory
    let categories = course.relationships.field_secondary_category.data.map(category => category.id)
    categories.push(course.relationships.field_category.data.id)

    categories.forEach((category) => {
      if (category === filters.category) {
        hasCategory = true
      }
    })
    return hasCategory && course
  }

  filterByLearningPath = (course) => {
    let { filters } = this.props

    // For the active learning path, find the full entity and all coursed tied to it
    let learningPath = this.props.learningPaths.find(path => path.id === filters.learningPath)
    let courseIds = learningPath.relationships.field_courses.data.map((pathCourse) => {
      return pathCourse.id
    })

    return courseIds.includes(course.id)
  }

  filterCourses = (course) => {

    let { filters, categories, session } = this.props

    let byQuery = filters.searchQuery.length > 0 ? this.filterByQuery(course) : course
    let isExcluded = session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)

    if (!isExcluded) {
      if (!byQuery) {
        return false
      }
      else {
        let byCategory = filters.category !== 'any' ? this.filterByCategory(byQuery) : byQuery
        if (!byCategory) {
          return false
        }
        else {
          let byLearningPath = filters.learningPath !== 'any' ? this.filterByLearningPath(byCategory) : byCategory
          if (!byLearningPath) {
            return false
          }
          else {
            let byRemaining = this.filterByRemaining(byCategory)
            if (!byRemaining) {
              return false
            }
            else {
              return byRemaining
            }
          }
        }
      }
    }

  }

  render() {
    const { courses, coursesFetched, assessments, assessmentsFetched, badges, categories, activeLearningPath, viewStyle } = this.props

    // activeCourses are non-archived courses
    let activeCourses = courses.filter(course => !course.attributes.field_archive)

    let sortedCourses = _.sortBy(activeCourses, 'attributes.name')
    let coursesCompleted = _.remove(sortedCourses, (course) => {
      let courseAssessment = assessments.find(assessment => assessment.relationships.field_course.data.id === course.id)
      return courseAssessment && courseAssessment.attributes.field_status === 'completed' && course
    })

    let completedCoursesLast = _.concat(sortedCourses, coursesCompleted)
    let content

    if (courses && assessments) {

      content = <>
        {
          completedCoursesLast.filter(this.filterCourses).map((course, index) => {

            let categoryId = course.relationships.field_category.data.id
            let category = categories.find(cat => cat.id === categoryId)
            let targetAssessment = assessments.find(assessment => assessment.relationships.field_course.data.id === course.id)

            let secondaryCategories = course.relationships.field_secondary_category.data.map((secondaryCategory) => {
              return categories.find(cat => cat.id === secondaryCategory.id)
            })

            let badge = badges.length && badges.find((badge) => {
              let hasCourse = badge.relationships.field_course.data.find((badgeCourse) => {
                return badgeCourse.id === course.id
              })
              return hasCourse
            })

            let card = <CourseCard
              key={index}
              animationDelay={index * .05 + 's'}
              course={course}
              assessment={targetAssessment}
              category={category}
              secondaryCategories={secondaryCategories}
              status={targetAssessment && targetAssessment.attributes.field_status}
              badge={badge}
              currentPage="explore"
            />

            if (viewStyle === 'list') {
              card = <CourseListItem
                key={index}
                animationDelay={index * .05 + 's'}
                course={course}
                assessment={targetAssessment}
                category={category}
                secondaryCategories={secondaryCategories}
                status={targetAssessment && targetAssessment.attributes.field_status}
                badge={badge}
                currentPage="explore"
              />

            }
            return card
          })
        }
      </>
    }

    return (
      <div>
        {
          assessmentsFetched && coursesFetched ?
            <>
              <ViewToggle />
              {
                courses && assessments &&
                <>
                  <div className={classNames('itemCount', isMobile && 'mobile')}>
                    <span>{completedCoursesLast.filter(this.filterCourses).length}</span>
                    {
                      completedCoursesLast.filter(this.filterCourses).length === 1 ?
                        <>Micro-Course</>
                        :
                        <>Micro-Courses</>
                    }
                  </div>
                  {
                    isMobileOnly &&
                    <div className="mobileFilters">
                      <ExploreFilters />
                    </div>
                  }
                  {
                    activeLearningPath &&
                    <div className="learningPathInfo">
                      <h2>
                        <span className="icon learningPath"></span>
                        {activeLearningPath.attributes.name}
                      </h2>
                      <p>{activeLearningPath.attributes.field_description}</p>
                    </div>
                  }
                  {
                    viewStyle === 'grid' ?
                      <div className={classNames('course-library course-grid', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
                        <Grid container spacing={3}>
                          {content}
                        </Grid>
                      </div>
                      :
                      <ul className={classNames('course-library course-list', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
                        {content}
                      </ul>
                  }
                </>
              }
            </>
            :
            <Loading message="Getting micro-courses..." />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreLayout);