import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router'
import { generateTimestamp } from '../functions'

class CourseListItemCompleted extends Component {

  render() {
    let { course, category, assessment } = this.props

    let categoryLabel = category.attributes.name.toLowerCase()
    let level = course.attributes.field_level.toLowerCase()
    let updated = assessment && generateTimestamp(assessment.attributes.changed)

    return (
      <li style={{ animationDelay: this.props.animationDelay }} className="course-list-item completed">
        <Link to={'course/' + course.attributes.drupal_internal__id}>
          <div className='category-icon-wrapper'>
            <span className={classNames('category-icon', categoryLabel)}></span>
            <span className="icon check"/>
          </div>

          <div className="details">
            <div className="course-label">
              <span className="category-label">{categoryLabel}</span>
              <div className="course-level">
                <span className="icon star" />
                { level === 'intermediate' && <span className="icon star" /> }
                { level === 'advanced' && 
                  <>
                    <span className="icon star"></span>
                    <span className="icon star"></span>
                  </> 
                }
              </div>
            </div>
            <h2>{course.attributes.name}</h2>
            <footer className="timestamp in-progress">Completed {updated}</footer>
            {
              assessment.attributes.field_comments &&
              <div className="feedback">
                <em>"{assessment.attributes.field_comments}"</em>
              </div>
            }
          </div>
        </Link>
      </li>
    )
  }
}

export default CourseListItemCompleted;