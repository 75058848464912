import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeNavbar } from '../actions'
import { getLearningPaths, getUserLearningPaths } from 'common/actions/course'

import BadgesLayout from './BadgesLayout'
import CoursesCompletedLayout from './CoursesCompletedLayout'
import LearningPathsLayout from './LearningPathsLayout'
import Loading from './Loading'

import Sidebar from './Sidebar'

const mapStateToProps = ({ session, learningPaths, sidebar }, ownProps) => {

  return {
    session,
    learningPaths,
    sidebar
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getLearningPaths,
  getUserLearningPaths
}

class AchievementsLayout extends Component {

  state = {
    fetched: false
  }

  componentDidMount = async () => {
    const { session, learningPaths } = this.props

    this.props.changeNavbar('achievements')
    !learningPaths.fetched && await this.props.getLearningPaths()
    !session.fetched.userLearningPaths && await this.props.getUserLearningPaths(this.props.session.user)

    this.setState({ fetched: true })

  }

  render() {
    const page = this.props.sidebar.pages.achievements
    const { fetched } = this.state

    return (
      <div className="layout achievements">
        <Sidebar currentPage="achievements" activeItem={page.activeItem} items={page.items} />
        {
          fetched ?
            <>
              {page.activeItem === 'badgesEarned' && <BadgesLayout />}
              {page.activeItem === 'completed' && <CoursesCompletedLayout />}
              {page.activeItem === 'learningPathsCompleted' && <LearningPathsLayout />}
            </>
            :
            <Loading message="Getting your achievements..." />
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AchievementsLayout);
