import React, { Component } from 'react'
import { connect } from 'react-redux'

import CourseListItemCompleted from './CourseListItemCompleted'
import CoursesCompletedFilters from './CoursesCompletedFilters'
import ViewToggle from './ViewToggle'
import CourseCard from './CourseCard'
import Loading from './Loading'

import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { Grid } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'

import { getAssessmentsByStatus } from '../actions'

const mapStateToProps = ({ session, sidebar, assessments, categories, courses, viewStyle }, ownProps) => {

  let sortedCategories = _.sortBy(categories, (category) => {
    return category.attributes.name
  })

  return {
    session,
    sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: sidebar.pages.achievements.items.find(item => item.id === 'completed').filters,
    completedAssessments: assessments.completed,
    assessments: assessments.all,
    categories: sortedCategories,
    courses: courses.courseData,
    viewStyle
  }
}

const mapDispatchToProps = {
  getAssessmentsByStatus
}

class CoursesCompletedLayout extends Component {
  state = {
    fetched: false
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    await this.props.getAssessmentsByStatus('completed')
    this.setState({ fetched: true })
  }

  filterByQuery = (assessment) => {
    let { filters } = this.props
    let course = this.getCourseFromAssessment(assessment)
    if (course.attributes.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) > -1) {
      return assessment
    }
  }

  filterByCategory = (assessment) => {
    let { filters } = this.props
    let hasCategory

    let course = this.getCourseFromAssessment(assessment)
    let categories = course.relationships.field_secondary_category.data.map(category => category.id)
    categories.push(course.relationships.field_category.data.id)

    categories.forEach((category) => {
      if (category === filters.category) {
        hasCategory = true
      }
    })
    return hasCategory && assessment
  }

  filterAssessments = (assessment) => {

    let { filters } = this.props

    let byQuery = filters.searchQuery.length > 0 ? this.filterByQuery(assessment) : assessment
    if (!byQuery) {
      return false
    }
    else {
      let byCategory = filters.category !== 'any' ? this.filterByCategory(byQuery) : byQuery
      if (!byCategory) {
        return false
      }
      else {
        return byCategory
      }
    }

  }

  getCourseFromAssessment = (assessment) => {
    return this.props.courses.find(course => assessment.relationships.field_course.data.id === course.id)
  }

  render() {

    const { completedAssessments, categories, filters, viewStyle } = this.props
    const { fetched } = this.state

    let sortedAssessments = completedAssessments && _.orderBy(completedAssessments, (assessment) => {
      if (filters.sort === 'name') {
        return this.getCourseFromAssessment(assessment).attributes.name
      }
      else {
        return moment(assessment.attributes.changed).format('YYYY-MM-DD HH:MM:SS')
      }
    }, filters.sort === 'dateNewest' && ['desc'])

    let content = <>
      {
        sortedAssessments && sortedAssessments.filter(this.filterAssessments).map((assessment, index) => {

          let course = this.getCourseFromAssessment(assessment)
          let category = course && categories.find(cat => course.relationships.field_category.data.id === cat.id)
          let secondaryCategories = course && course.relationships.field_secondary_category.data.map((secondaryCategory) => {
            return categories.find(cat => cat.id === secondaryCategory.id)
          })

          if (course && viewStyle === 'grid') {
            return (
              <CourseCard
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                course={course}
                assessment={assessment}
                category={category}
                secondaryCategories={secondaryCategories}
                status={assessment && assessment.attributes.field_status}
                currentPage="achievements"
              />
            )
          }
          else if (course) {
            return (
              <CourseListItemCompleted
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                assessment={assessment}
                course={course}
                category={category}
                currentPage="achievements"
              />
            )
          }
          else {
            return false
          }
        })
      }
    </>

    return (
      <div className="page completedCourses">

        {
          fetched ?
            <>
              <ViewToggle />

              <div className="itemCount">
                <span>{completedAssessments.filter(this.filterAssessments).length}</span>
                {
                  completedAssessments.filter(this.filterAssessments).length === 1 ?
                    <>Completed Micro-Course</>
                    :
                    <>Completed Micro-Courses</>
                }
              </div>

              {
                isMobileOnly &&
                <div className="mobileFilters">
                  <CoursesCompletedFilters />
                </div>
              }

              {
                viewStyle === 'grid' ?
                  <div className={classNames('course-library course-grid', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
                    <Grid container spacing={3}>
                      {content}
                    </Grid>
                  </div>
                  :
                  <ul className={classNames('course-library course-list', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
                    {content}
                  </ul>
              }
            </>
            :
            <Loading message="Getting your achievements..." />
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesCompletedLayout);