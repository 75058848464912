import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Grid } from '@material-ui/core'
import classNames from 'classnames'
import { generateTimestamp, getCategoryLabel } from '../functions'
//import { Button } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'

const mapStateToProps = ({ badges, courses, navbar, assessments, categories }, ownProps) => {

  let badgeData = [...badges.badgeData]
  badgeData.forEach((badge) => {
    badge.courses = badge.relationships.field_course.data.map((badgeCourse) => {
      return courses.courseData && courses.courseData.find(course => course.id === badgeCourse.id)
    })
  })

  return {
    navbar,
    assessments: assessments.all,
    categories,
    badges: badgeData
  }
}

class BadgeItem extends Component {

  handleClick = (id) => {
    this.props.setActiveBadge(id)
  }

  render() {
    let { badge, earnedBadge, activeBadgeId, assessments, categories } = this.props

    let isActiveBadge = activeBadgeId === badge.id
    let badgeImageURL = badge.image ? process.env.REACT_APP_API_URL + badge.image.attributes.uri.url : null
    let completedCourses = 0

    // Filter out archived courses
    let badgeCourses = badge.courses.filter(course => course && !course.attributes.field_archive)

    // Calculate the percentage of courses completed for this badge
    badgeCourses.forEach((course) => {
      // Find out if there's an assessment for this course
      let assessment = assessments.find(assessment => course.id === assessment.relationships.field_course.data.id)
      // Include it if it's completed
      if (assessment && assessment.attributes.field_status === 'completed') {
        completedCourses++
      }
    })

    let percentCompleted = (completedCourses / badgeCourses.length) * 100
    let computedHeight = badgeCourses.length * 45 + 168
    let baseHeight = isMobileOnly ? 75 : 90

    return (
      <Grid item xs={3} className="badgeWrapper" style={{ animationDelay: this.props.animationDelay }}>
        <div
          style={{ height: isActiveBadge ? computedHeight : baseHeight }}
          onClick={() => { this.handleClick(badge.id) }}
          className={classNames('badgeItem', { 'active': isActiveBadge }, { 'completed': earnedBadge })}>

          <span onClick={() => { this.handleClick(badge.id, true) }} className="icon caret" />

          <div className="innerTop">

            <div className="peeler" style={{ animationDelay: this.props.animationDelay }}>
              <div className="front" style={{ animationDelay: this.props.animationDelay }}>
                <div className="circle" style={{ backgroundImage: 'url(' + badgeImageURL + ')', animationDelay: this.props.animationDelay }}>
                </div>
              </div>
              <div className="back" style={{ animationDelay: this.props.animationDelay }}>
                <div className="circle" data-badge={badge.attributes.name} style={{ animationDelay: this.props.animationDelay }}></div>
              </div>
            </div>

            {earnedBadge && <span className="icon check" style={{ animationDelay: this.props.animationDelay }} />}
            <div className="details">
              <h2>{badge.attributes.name}</h2>

              {
                !earnedBadge ?
                  <p className="statusText">
                    {completedCourses}/{badgeCourses.length} {badgeCourses.length > 1 ? 'Courses' : 'Course'} Completed
                  </p>
                  :
                  <p className="statusText">
                    Earned {generateTimestamp(earnedBadge.attributes.created)}
                  </p>
              }

              {
                !earnedBadge &&
                <div className="statusBarWrapper">
                  <div className="statusBar" style={{ width: percentCompleted + '%' }}>
                    <div className="statusBarInner"></div>
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="innerBottom">
            <header>
              {badgeCourses.length > 1 ? 'Courses ' : 'Course '}
              {earnedBadge ? 'Completed' : 'Required'}
            </header>
            <ul>
              {
                badgeCourses.map((course) => {
                  let coursePath = "/course/" + course.attributes.drupal_internal__id
                  let assessment = assessments.find(assessment => course.id === assessment.relationships.field_course.data.id)
                  return (
                    <li key={course.id}>
                      <Link to={coursePath}>
                        <div className='category-icon-wrapper'>
                          <span className={classNames('category-icon', getCategoryLabel(categories, course.relationships.field_category.data.id))}></span>
                        </div>
                        <div className="title">{course.attributes.name}</div>
                        <div className="icon-wrapper">
                          {assessment && <span className={classNames("icon", assessment.attributes.field_status)} />}
                        </div>
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </Grid>
    )
  }
}

export default connect(mapStateToProps)(BadgeItem);