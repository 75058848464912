/*global chrome*/

import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'

// Middleware
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createGoogleAnalyticsMiddleware } from './middleware/googleAnalytics'

import './styles/index.scss'

// Material UI Theme
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import App from './components/App'
import LoginLayout from './components/LoginLayout'
import CourseLayout from './components/CourseLayout'
import LearnLayout from './components/LearnLayout'
import InProgressLayout from './components/InProgressLayout'
import AchievementsLayout from './components/AchievementsLayout'
import ProfileLayout from './components/ProfileLayout'
import LearningPathLayout from './components/LearningPathLayout'

import initialState from './initialState.js'
import { getToken, getUserId, getUser } from 'common/actions/user'
import { getCategories, getExtensionVersion } from './actions'
import { isChrome, isMobile } from 'react-device-detect'

let middleware = [thunkMiddleware]

if (process.env.DEBUG) {
  const logger = createLogger()
  middleware = [...middleware, logger]
}

if (process.env.REACT_APP_GA_ID) {
  const googleAnalytics = createGoogleAnalyticsMiddleware(process.env.REACT_APP_GA_ID)
  middleware = [...middleware, googleAnalytics]
}

let store = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
)

const history = syncHistoryWithStore(browserHistory, store)

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Nunito', 'sans-serif']
  },
  palette: {
    primary: {
      main: '#59C3E5'
    },
    secondary: {
      main: '#91D36C'
    }
  }
})

// Try to get the extension version if we're in Chrome
!isMobile && isChrome && chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, 'getVersion', (response) => {
  if (response && response.version) {
    store.dispatch(getExtensionVersion(response.version))
  }
})

store.dispatch(getUserId()).then(() => {
  store.dispatch(getUser(store.getState().session.userId)).then(() => {

    // We have to do this now for PATCH requests, see
    // https://drupal.stackexchange.com/questions/230159/message-x-csrf-token-request-header-is-missing/230238
    store.dispatch(getToken()).then(() => {
      store.dispatch(getCategories()).then(() => {

        let { user } = store.getState().session
        if (!user) {
          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <LoginLayout />
              </Provider>
            </ThemeProvider>,
            document.getElementById('root')
          )
        }
        else {
          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                  <Route path="/" component={App}>
                    <IndexRoute component={LearnLayout} />
                    <Route path="/profile" component={ProfileLayout} />
                    <Route path="/learning-path(/:pathId)" component={LearningPathLayout} />
                    <Route path="/course(/:courseId)" component={CourseLayout} />
                    <Route path="/in-progress" component={InProgressLayout} />
                    <Route path="/achievements" component={AchievementsLayout} />
                  </Route>
                </Router>
              </Provider>
            </ThemeProvider>,
            document.getElementById('root')
          )
        }
      })
    })
  })
})

export default store