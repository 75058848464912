import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateProfile, uploadImage, updateUserPicture } from 'common/actions/user'
import { Button } from '@material-ui/core'

import moment from 'moment'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

const mapStateToProps = ({ session }, ownProps) => {
  return {
    session,
    userPicture: session.userPicture
  }
}

const mapDispatchToProps = {
  uploadImage,
  updateUserPicture,
  updateProfile
}

class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    const { userPicture } = this.props
    let userPictureSrc = userPicture ? process.env.REACT_APP_API_URL + userPicture.attributes.uri.url : require('../img/profile-pic.svg')

    this.state = {
      userPictureSrc: userPictureSrc,
      isUploading: false
    }
  }

  onDrop = (file) => {

    let { user } = this.props.session
    let uploaded = file[0] ? file[0] : file.target.files[0]

    if (uploaded) {

      // If it's an image
      if (uploaded.type.indexOf('image') > -1) {

        // Update the UI
        this.setState({ isDragging: false, isUploading: true, userPicture: uploaded.preview })

        // Read the file
        let reader = new FileReader()
        reader.readAsDataURL(uploaded)

        // Once we're done, get the file and name it
        reader.onloadend = async (event) => {
          let filename = user.id + '-' + new moment().unix() + '-' + uploaded.name

          // And upload it to the user
          let url = '/api/user/user/' + user.id + '/user_picture'
          let newUserPicture = await this.props.uploadImage(event.target.result, url, filename)

          await this.props.updateUserPicture(newUserPicture)
          this.setState({ isUploading: false })
        }
      }
    }
    else {
      this.setState({ isDragging: false, isUploading: false })
    }
  }

  render() {
    const { isUploading, isDragging } = this.state
    const { userPicture } = this.props

    return (
      <div className="profilePictureWrapper">
        <Dropzone
          accept="image/jpeg, image/png, image/jpg"
          onDragEnter={() => this.setState({ isDragging: true })}
          onDragLeave={() => this.setState({ isDragging: false })}
          onDrop={this.onDrop.bind(this)}>
          {({ getRootProps }) => (
            <div {...getRootProps()} className={classNames("fileUpload", { 'isDragging': isDragging }, { 'isUploading': isUploading })}>
              <input onChange={this.onDrop.bind(this)} type="file" />
              {
                userPicture ?
                  <div className="profilePicture userPicture" style={{ backgroundImage: 'url(' + process.env.REACT_APP_API_URL + userPicture.attributes.uri.url + ')' }} />
                  :
                  <>
                    <img alt="profilePicture" className="profilePicture" src={require('../img/profile-pic.svg')} />
                    <div className="border" />
                    {
                      <div className="tooltip">
                        {
                          isUploading ?
                            <>Uploading Image...</>
                            :
                            <>
                              Click to Upload<br />or Drag and Drop
                          <span>256MB limit<br />png gif jpg jpeg</span>
                            </>
                        }
                      </div>
                    }
                  </>
              }
            </div>
          )}
        </Dropzone>
        {
          userPicture &&
          <div className="tooltip">
            <Button onClick={this.props.removeImage} className="button small">Remove Image</Button>
          </div>
        }
        {
          !userPicture &&
          <div className="tooltip">Click or Drag and Drop<br />to Upload Image</div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
