import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { filterCourses } from '../actions'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'

import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import '../styles/sidebar.scss'
import { updateFilters } from '../actions'

const mapStateToProps = (state, ownProps) => {

  return {
    sidebar: state.sidebar,
    isExpanded: state.sidebar.mobileFiltersExpanded,
    filters: state.sidebar.pages.achievements.items.find(item => item.id === 'badgesEarned').filters
  }
}

const mapDispatchToProps = {
  updateFilters
}

class BadgeFilters extends Component {

  updateFilters = (event, key) => {
    let filters = { ...this.props.filters }
    filters[key] = event.target.value
    this.props.updateFilters(filters, 'badgesEarned')
  }

  resetFilters = () => {
    this.props.updateFilters({
      sort: 'dateEarned'
    }, 'badgesEarned')
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, 'mobile')
  }

  render() {
    const { sort } = this.props.filters

    return (
      <form className={classNames('filters badgesEarned', this.props.sidebar.isCollapsed && 'collapsed', isMobile && this.props.isExpanded && 'expanded')}>
        <header >
          <strong>Sort Badges</strong>
        </header>

        <FormControl>
          <InputLabel>Sort By</InputLabel>
          <Select value={sort ? sort : 'dateEarned'} onChange={(event) => { this.updateFilters(event, 'sort') }}>
            <MenuItem value={'dateNewest'}>Date Earned (Newest First)</MenuItem>
            <MenuItem value={'dateOldest'}>Date Earned (Oldest First)</MenuItem>
            <MenuItem value={'name'}>Badge Name</MenuItem>
          </Select>
        </FormControl>
        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon"/>
        </div>

      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeFilters);