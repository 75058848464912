import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../styles/cards.scss'

import Sidebar from './Sidebar'
import ExploreLayout from './ExploreLayout'
import BadgesLayout from './BadgesLayout'
import LearningPathsLayout from './LearningPathsLayout'
import DashboardLayout from './DashboardLayout'

import { changeNavbar } from '../actions'
import { getLearningPaths } from 'common/actions/course'

const mapStateToProps = ({ session, courses, learningPaths, badges, assessments, sidebar }, ownProps) => {

  return {
    session: session,
    courses: courses,
    learningPaths: learningPaths,
    badges: badges,
    assessments: assessments,
    sidebar: sidebar
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getLearningPaths
}

class LearnLayout extends Component {

  state = {
    fetched: false,
    loadingMessage: 'Building Your Dashboard...'
  }

  componentDidMount = () => {
    this.props.changeNavbar('learn')
    if (!this.props.learningPaths.fetched) {
      this.props.getLearningPaths()
    }

  }

  render() {
    const page = this.props.sidebar.pages.learn

    return (
      <div className="layout learn">
        <Sidebar currentPage="learn" activeItem={page.activeItem} items={page.items} />
        <>
          {page.activeItem === 'dashboard' && <DashboardLayout />}
          {page.activeItem === 'learningPaths' && <LearningPathsLayout />}
          {page.activeItem === 'badges' && < BadgesLayout />}
          {page.activeItem === 'explore' && <ExploreLayout />}
        </>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnLayout);
