/* global chrome */
import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { generateTimestamp, getActiveUserLearningPath } from '../functions'
import { Button } from '@material-ui/core'
import { isMobile, isChrome } from 'react-device-detect'
import { updateAssessment } from 'common/actions/course'

const mapStateToProps = ({ learningPaths, session, extension }, ownProps) => {

  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath = session.userLearningPaths && getActiveUserLearningPath(session.userLearningPaths, learningPaths, ownProps.course)

  return {
    activeUserLearningPath,
    learningPaths,
    session,
    extension
  }
}

const mapDispatchToProps = {
  updateAssessment
}

class CourseListItem extends Component {
  state = {
    promptRemove: false
  }

  remove = (id) => {
    this.setState({ promptRemove: false, removed: true })
    this.props.removeAssessment(id)
  }

  resume = () => {
    !isMobile && isChrome && chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: 'startAssessment',
      assessmentId: this.props.assessment.id,
      activeCourse: this.props.course,
      learningPaths: this.props.learningPaths,
      userLearningPath: this.props.activeUserLearningPath
    })
  }

  render() {

    let { course, category, secondaryCategories, status, badge, assessment, isExcluded, currentPage } = this.props
    let { promptRemove, removed } = this.state

    let coursePath = "/course/" + course.attributes.drupal_internal__id
    let categoryLabel = category.attributes.name.toLowerCase()
    let level = course.attributes.field_level.toLowerCase()
    let isCompleted = status === 'completed'
    let isInProgress = status === 'in_progress'
    let isSaved = status === 'saved_for_later'
    let isSubmitted = status === 'grading_in_progress' || status === 'submitted'

    let timestamp = assessment && generateTimestamp(assessment.attributes.created)
    let updated = assessment && generateTimestamp(assessment.attributes.changed)
    let hasComments = assessment && assessment.attributes.field_comments
    let isRemovable = !hasComments && status === 'in_progress' && currentPage !== "explore"

    return (
      <>
        {
          (!removed || this.props.currentPage === 'learningPath') &&
          <li
            style={{ animationDelay: this.props.animationDelay }}
            className={classNames(
              'course-list-item',
              { 'isSaved': isSaved },
              { 'hasFeedback': hasComments && !isSubmitted },
              { 'isCompleted': isCompleted },
              { 'isExcluded': isExcluded }
            )}
          >
            <Link to={!isExcluded && coursePath}>
              <header>
                <div className='category-icon-wrapper'>
                  <span className={classNames('category-icon', categoryLabel)}></span>
                </div>
                {
                  secondaryCategories && secondaryCategories.length > 0 && secondaryCategories.map((category) => {
                    let categoryLabel = category.attributes.name.toLowerCase()
                    return (
                      <div key={category.id} className='category-icon-wrapper secondary'>
                        <span className={classNames('category-icon', categoryLabel)}></span>
                      </div>
                    )
                  })
                }

                <div className="course-label">
                  <span className="category-label">{categoryLabel}</span>
                  <div className="course-level">
                    <span className="icon star"></span>
                    {level === 'intermediate' && <span className="icon star"></span>}
                    {level === 'advanced' &&
                      <>
                        <span className="icon star"></span>
                        <span className="icon star"></span>
                      </>
                    }
                  </div>
                  {badge && <span className="icon badge"></span>}
                </div>
              </header>
              <h2>{course.attributes.name}</h2>
              <footer className="course-details">
                <p>{course.attributes.field_course_objective}</p>
              </footer>
              {
                isInProgress && !removed &&
                <footer className="timestamp in-progress">
                  {
                    hasComments ?
                      <>Updated {updated}</>
                      :
                      <>Started {timestamp}</>
                  }
                </footer>
              }
              {
                isExcluded && this.props.session.group &&
                <p className="excludedText">
                  <strong>{this.props.session.group.label[0].value}</strong> has chosen to exclude <strong>{category.attributes.name}</strong> micro-courses.
                </p>
              }
              {
                isSaved &&
                <footer className="timestamp in-progress">
                  {
                    this.state.isStartingFromSave ?
                      <>Starting Challenge...</>
                      :
                      <>Saved {timestamp}</>
                  }
                </footer>
              }
              {
                isInProgress && hasComments &&
                <p className="feedback">
                  <em>"{assessment.attributes.field_comments}"</em>
                </p>
              }
              {
                isSubmitted &&
                <footer className="timestamp in-progress">
                  Submitted {updated}
                </footer>
              }
              {
                isCompleted &&
                <footer className="timestamp">
                  Completed {updated}
                </footer>
              }
            </Link>
            {
              isInProgress && this.props.extension.version && !removed &&
              <Button onClick={this.resume} className="button small resume">Resume</Button>
            }
            {
              isSaved && !this.state.isStartingFromSave &&
              <Button onClick={() => { this.remove(assessment.id) }} className="button small unsave">
                <span className="icon pin" />Unsave
              </Button>
            }
            {
              isRemovable && !removed &&
              <Button style={{ right: !this.props.extension.version && 15 }} onClick={() => { this.setState({ promptRemove: true }) }} className="button small remove"><img src={require('../img/trash.svg')} alt="remove" /></Button>
            }
            <footer className={classNames('prompt', { 'active': promptRemove })}>
              <>
                <p>Are you sure you want to restart this challenge?</p>
                <div className="flexRow">
                  <Button onClick={() => { this.setState({ promptRemove: false }) }} className="button small">Cancel</Button>
                  <Button onClick={() => { this.remove(assessment.id) }} className="button small">Remove</Button>
                </div>
              </>
            </footer>
          </li>
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseListItem);