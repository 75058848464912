
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { setViewStyle } from '../actions'


const mapStateToProps = ({ viewStyle }, ownProps) => {
  return {
    viewStyle
  }
}

const mapDispatchToProps = {
  setViewStyle
}

class ViewToggle extends Component {

  render() {
    const { viewStyle } = this.props

    return (
      <div className="view-toggle">
        <span 
          onClick={() => { this.props.setViewStyle('grid')}} 
          className={classNames('icon grid', {'active': viewStyle === 'grid'})}
        />
        <span
          onClick={() => { this.props.setViewStyle('list')}}
          className={classNames('icon list', {'active': viewStyle === 'list'})}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewToggle);