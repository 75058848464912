import { isMobile } from 'react-device-detect'

const state = {
  navbar: {
    activeItem: 'learn',
  },
  extension: {},
  sidebar: {
    isCollapsed: isMobile,
    pages: {
      learn: {
        activeItem: 'dashboard',
        items: [
          {
            title: 'Dashboard',
            id: 'dashboard'
          },
          {
            title: 'Learning Paths',
            id: 'learningPaths',
            filters: {
              type: 'any',
              status: 'any',
            }
          },
          {
            title: 'Earn Badges',
            id: 'badges',
            filters: {
              searchQuery: '',
              category: 'any',
              remainingOnly: true
            }
          },
          {
            title: 'Explore',
            id: 'explore',
            filters: {
              searchQuery: '',
              category: 'any',
              learningPath: 'any',
              remainingOnly: true
            }
          }
        ],
      },
      inProgress: {
        activeItem: 'in_progress',
        items: [
          {
            title: 'Saved for Later',
            id: 'saved_for_later'
          },
          {
            title: 'Started',
            id: 'in_progress'
          },
          {
            title: 'Feedback',
            id: 'feedback'
          },
          {
            title: 'Submitted',
            id: 'submitted',
          }
        ],
      },
      achievements: {
        activeItem: 'completed',
        items: [
          {
            title: 'Courses Completed',
            id: 'completed',
            filters: {
              searchQuery: '',
              category: 'any',
              sort: 'dateNewest'
            }
          },
          {
            title: 'Badges Earned',
            id: 'badgesEarned',
            filters: {
              sort: 'dateNewest'
            }
          },
          {
            title: 'Learning Paths',
            id: 'learningPathsCompleted',
            filters: {
              sort: 'dateNewest'
            }
          }
        ]
      }
    }
  },
  courses: {
    categories: [],
    courseData: []
  },
  viewStyle: isMobile ? 'list' : 'grid'
}

export default state
