import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { filterCourses } from '../actions'
import {
  Select,
  MenuItem,
  FormControl,
  Button,
  InputLabel
} from '@material-ui/core'

import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import '../styles/sidebar.scss'
import { updateFilters } from '../actions'

const mapStateToProps = ({session, learningPaths, sidebar}, ownProps) => {

  return {
    types: learningPaths.types,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: sidebar.pages.learn.items.find(item => item.id === 'learningPaths').filters
  }
}

const mapDispatchToProps = {
  updateFilters
}

class LearningPathFilters extends Component {

  updateFilters = (event, key) => {
    let filters = { ...this.props.filters }
    filters[key] = event.target.value
    this.props.updateFilters(filters, 'learningPaths')
  }

  resetFilters = () => {
    this.props.updateFilters({
      type: 'any',
      status: 'any',
    }, 'learningPaths')
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, 'mobile')
  }

  render() {
    const { status, type } = this.props.filters

    return (
      <form className={classNames('filters learningPaths', this.props.sidebar.isCollapsed && 'collapsed', isMobile && this.props.isExpanded && 'expanded')}>
        <header>
          <strong>Filter Learning Paths</strong>
          <Button className="button small" onClick={this.resetFilters}>Reset</Button>
        </header>
 
        <FormControl className="inputSelect type">
          <span className="icon learningPath"/>
          <InputLabel id="label-type-select">Type</InputLabel>
          <Select
            labelId="label-type-select"
            id="type-select"
            value={type}
            onChange={(event) => { this.updateFilters(event, 'type') }}>
            <MenuItem key={'any'} value={'any'}>Any</MenuItem>
            {
              this.props.types && this.props.types.map(type => {
                return (
                  <MenuItem key={type.id} value={type.attributes.name}>{type.attributes.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl className="inputSelect status">
          <span className="icon check-black"/>
          <InputLabel id="label-status-select">Status</InputLabel>
          <Select
            labelId="label-status-select"
            id="status-select"
            value={status}
            onChange={(event) => { this.updateFilters(event, 'status') }}>
            <MenuItem value={'any'}>Any</MenuItem>
            <MenuItem value={'not_started'}>Not Started</MenuItem>
            <MenuItem value={'in_progress'}>In Progress</MenuItem>
            <MenuItem value={'completed'}>Completed</MenuItem>
          </Select>
        </FormControl>

        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon"/>
        </div>

      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathFilters);