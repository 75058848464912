import ReactGA from 'react-ga'

let currentPage = ''

export function createGoogleAnalyticsMiddleware (gaID) {

  ReactGA.initialize(gaID)
  return store => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      let { payload: { pathname, search } } = action
      let nextPage = `${pathname}${search}`
      if (currentPage !== nextPage) {
        currentPage = nextPage
        ReactGA.pageview(nextPage)
      }
    }
    return next(action)
  }

}
