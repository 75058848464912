import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import '../styles/login.scss'

class LoginLayout extends Component {

  render() {
    return (
      <div className="page login">
        <div className="background" />
        <div className="loginWrapper">
          <div className="flexRow">
            <img src={require('../img/logo.png')} alt="MobileMind"/>
          </div>
          <div className="flexRow">
            <Button className="button login" href={`${process.env.REACT_APP_API_URL}/user/login/google`}>
              <span className="icon google" />Sign in with Google
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(LoginLayout);
