
import React, { Component } from 'react'
import { Card, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { setSidebar, getAssessmentsForCourses, updateFilters } from '../actions'
import { Link } from 'react-router'
import { isCourseCategoryExcluded } from '../functions'

const mapStateToProps = ({ session, categories, assessments, learningPaths, courses }, ownProps) => {

  return {
    session,
    categories,
    assessments,
    courses: courses.courseData,
    learningPath: learningPaths.paths && learningPaths.paths.find(path => path.id === ownProps.widget.userLearningPath.relationships.field_learning_path.data.id)
  }
}

const mapDispatchToProps = {
  setSidebar,
  updateFilters,
  getAssessmentsForCourses
}

class WidgetLearningPath extends Component {

  componentDidMount() {
    let { learningPath } = this.props
    let completedCourseIds = this.props.widget.userLearningPath.relationships.field_courses_completed.data.map(course => course.id)
    let remainingCourseIds = learningPath && learningPath.relationships.field_courses.data.filter(course => !completedCourseIds.includes(course.id)).map(course => course.id)
    remainingCourseIds && this.props.getAssessmentsForCourses(remainingCourseIds)
  }

  generateMessage = (percentCompleted, coursesRemaining) => {

    let { learningPath, courses, session, assessments, categories } = this.props

    let completedCourseIds = this.props.widget.userLearningPath.relationships.field_courses_completed.data.map(course => course.id)
    let remainingCourseIds = learningPath && learningPath.relationships.field_courses.data.filter(course => !completedCourseIds.includes(course.id)).map(course => course.id)
    let remainingCourses = remainingCourseIds.map(courseId => courses.find(course => course.id === courseId))

    let nextCourse = remainingCourses && remainingCourses.find((course) => {

      let assessment = course && assessments.all.find(assessment => assessment.relationships.field_course.data.id === course.id)
      let assessmentSubmitted = assessment && (assessment.attributes.field_status === 'submitted' || assessment.attributes.field_status === 'completed')
      let isArchived = course && course.attributes.field_archive
      let isExcluded = course && session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)

      return !isArchived && !isExcluded && !assessmentSubmitted
    })

    let nextCourseTitle = nextCourse && nextCourse.attributes.name

    let message

    if (percentCompleted > 80) {
      message = <>You're so close! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (coursesRemaining === 1) {
      message = <>Only one more course to go! Finish this Learning Path by completing <strong>{nextCourseTitle}</strong>.</>
    }
    else if (coursesRemaining > 1 && (percentCompleted >= 50 && percentCompleted < 70)) {
      message = <>Over halfway there! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (percentCompleted > 0) {
      message = <>You're off to a great start! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (percentCompleted === 0) {
      message = <>Ready for action! Get started with the first course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }

    return nextCourse && message
  }

  render() {
    const { learningPath } = this.props
    const { label, userLearningPath } = this.props.widget

    if (learningPath && userLearningPath) {

      let courseIds = learningPath.relationships.field_courses.data.map(course => course.id)

      let pathCourses = this.props.courses.filter(course => courseIds.includes(course.id))
      let filteredPathCourses = pathCourses.filter((course) => {
        let isArchived = course.attributes.field_archive
        return !isArchived
      })

      let courseTotal = filteredPathCourses.length
      let courseCount = userLearningPath.relationships.field_courses_completed.data.length
      let percentCompleted = (courseCount / courseTotal) * 100
      let coursesRemaining = courseTotal - courseCount
  
      return (
        <Card
          className="widget learningPath"
          style={{ cursor: 'pointer', animationDelay: this.props.animationDelay }}>
          <Link to={'learning-path/' + learningPath.attributes.drupal_internal__id}>
            <div className="topContent">
              <div className={'widgetLabel success'}>{label}</div>
              <div className="pathImage" style={{ opacity: .2, backgroundImage: 'url("' + process.env.REACT_APP_API_URL + this.props.learningPath.image.attributes.uri.url + '")' }}/>
          
              <h2>{learningPath.attributes.name}</h2>
              <div className="pathStatus">
                {courseCount} / {courseTotal} Courses Completed
                <div className="statusBarWrapper">
                  <div className="statusBar" style={{ width: percentCompleted + '%' }}>
                    <div className="statusBarInner"></div>
                  </div>
                </div>
              </div>
  
            </div>
  
            <p>{this.generateMessage(percentCompleted, coursesRemaining)}</p>
  
            <footer>
              <Button onClick={this.resumeLearningPath} className="button small">Resume Learning Path</Button>
            </footer>
          </Link>
        </Card>
      )
    }
    else {
      return false
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLearningPath)