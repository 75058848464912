import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { MenuItem } from '@material-ui/core'

import { getBadge } from '../actions'
import { generateTimestamp } from '../functions'

import '../styles/navbar.scss'

const mapStateToProps = ({ session, courses, assessments, learningPaths, badges }, ownProps) => {
  let notification = ownProps.notification
  let type = notification.attributes.field_notification_type
  let path, content

  if (type === 'ulp_complete' && learningPaths) {
    let userLearningPath = session.userLearningPaths.find(path => path.id === notification.relationships.field_user_learning_path.data.id)
    let learningPath = userLearningPath && learningPaths.paths.find(path => path.id === userLearningPath.relationships.field_learning_path.data.id)
    if (userLearningPath && learningPath) {
      path = '/learning-path/' + learningPath.attributes.drupal_internal__id
      content = <>You completed the <strong>{learningPath.attributes.name}</strong> learning path!</>
    }
  }

  if (type === 'assessment_complete' || type === 'assessment_feedback') {
    let assessment = assessments.all.find(assessment => assessment.id === notification.relationships.field_assessment.data.id)
    let course = assessment && courses.courseData.find(course => course.id === assessment.relationships.field_course.data.id)
    if (assessment && course) {
      path = "/course/" + course.attributes.drupal_internal__id
      if (type === 'assessment_complete') {
        content = <>You completed <strong>{course.attributes.name}</strong>!</>
      }
      else {
        content = <>Feedback available for <strong>{course.attributes.name}</strong>.</>
      }
    }
  }

  if (type === 'user_badge_created') {
    let userBadge = session.userBadges.find(userBadge => userBadge.id === notification.relationships.field_user_badge.data.id)
    let badge = userBadge && badges.badgeData.find(badge => badge.id === userBadge.relationships.field_badge.data.id)
    if (userBadge && badge) {
      path = "/achievements"
      content = <>You earned the <strong>{badge.attributes.name}</strong> badge!</>
    }
  }

  return {
    session,
    courses: courses.courseData,
    badges,
    learningPaths: learningPaths.paths,
    assessments,
    path,
    content
  }
}

const mapDispatchToProps = {
  getBadge
}

class NotificationMenuItem extends Component {

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, hasOpened: true })
    this.props.fetchNotifications()
  }

  delete = (notification) => {
    this.props.deleteNotification(notification)
  }

  render() {
    const { notification, session } = this.props
    const { removed, path, content, seen } = this.props
    let lastFetched = session.user.attributes.field_notifications_last_fetched

    let isSeen = moment(lastFetched).isAfter(notification.attributes.created) || seen.includes(notification.id)
    let type = notification.attributes.field_notification_type
    return (
      <>
        {
          path && content &&
          <MenuItem key={notification.id}>
            <Link onClick={() => { this.props.handleClose(type === 'user_badge_created') }} className={classNames({ 'new': !isSeen }, { 'removed': removed.includes(notification.id) })} to={path}>
              <div className={classNames('icon', notification.attributes.field_notification_type)} />
              <div className="content">
                <div>{content}</div>
                <div className="timestamp">{generateTimestamp(notification.attributes.created)}</div>
              </div>
            </Link>
            <div onClick={() => { this.delete(notification) }} className="icon remove" />
          </MenuItem>
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenuItem);
