import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'

import { Grid, Card } from '@material-ui/core'
import { Link } from 'react-router'
import { getCategoryLabel } from '../functions'

import SanitizedHTML from './SanitizedHTML'

import { isMobile } from 'react-device-detect'
import { generateTimestamp } from '../functions'
import Loading from './Loading'
import { setSidebar, updateFilters } from '../actions'
import { getLearningPaths, getCourses } from 'common/actions/course'

const mapStateToProps = ({ session, navbar, learningPaths, categories, courses, sidebar }) => {

  let updatedPaths = [...learningPaths.paths]

  updatedPaths.forEach((path) => {

    let pathCourses = path.relationships.field_courses.data.map((pathCourse) => {
      return courses.courseData.find(course => pathCourse.id === course.id)
    }).filter(course => course)

    path.courses = pathCourses
    path.categories = pathCourses && Array.from(new Set(pathCourses.map(course => categories.find(category => category.id === course.relationships.field_category.data.id))))
    path.userLearningPath = session.userLearningPaths && session.userLearningPaths.find(userPath => userPath.relationships.field_learning_path.data.id === path.id)
    path.percentCompleted = path.userLearningPath && path.userLearningPath.relationships.field_courses_completed.data.length / pathCourses.length * 100
  })

  // Filter out archived learning paths unless they've already started it
  let filteredLearningPaths = updatedPaths.filter((path) => {
    return !path.attributes.field_learning_path_archive || path.userLearningPath
  })

  if (navbar.activeItem === 'achievements') {
    filteredLearningPaths = updatedPaths.filter(path => path.userLearningPath && path.userLearningPath.attributes.field_status === 'completed')
  }

  let filters = sidebar.pages.learn.items.find(item => item.id === 'learningPaths').filters

  return {
    learningPaths: filteredLearningPaths,
    filters,
    courses,
    navbar,
    sidebar,
    categories,
    session
  }
}

const mapDispatchToProps = {
  getLearningPaths,
  setSidebar,
  updateFilters,
  getCourses
}

class LearningPathsLayout extends Component {

  state = {
    fetched: false
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.setState({ fetched: true })
  }

  filterPaths = (path) => {

    let { filters } = this.props
    let byType = true, byStatus = true

    if (filters.type !== 'any') {
      byType = path.type === this.props.filters.type
    }
    if (filters.status !== 'any') {
      if (this.props.filters.status === 'not_started' && !path.userLearningPath) {
        byStatus = true
      }
      else {
        byStatus = path.userLearningPath && path.userLearningPath.attributes.field_status === this.props.filters.status
      }
    }
    return byType && byStatus

  }

  render() {
    const { learningPaths, navbar } = this.props

    // Sort in progress paths by percent completed
    let inProgressPaths = _.orderBy(learningPaths.filter(
      path => path.userLearningPath && path.userLearningPath.attributes.field_status === 'in_progress'), (path) => {
        return path.percentCompleted
      }, ['desc'])


    let unstartedPaths = learningPaths.filter(path => !path.userLearningPath)
    let completedPaths = learningPaths.filter(path => path.userLearningPath && path.userLearningPath.attributes.field_status === 'completed')
    let sortedPaths

    if (navbar.activeItem === 'achievements') {
      sortedPaths = _.orderBy(completedPaths, (path) => {
        return path.attributes.changed
      }, ['desc'])
    }
    else {
      sortedPaths = _.concat(inProgressPaths, unstartedPaths, completedPaths)
    }

    return (
      <div className="learning-path-library">
        <div className={classNames('itemCount', isMobile && 'mobile')}>
          <span>{sortedPaths.filter(this.filterPaths).length}</span>
          {
            navbar.activeItem === 'achievements' ?
              <>{sortedPaths.filter(this.filterPaths).length === 1 ? <>Learning Path Completed</> : <>Learning Paths Completed</>}</>
              :
              <>{sortedPaths.filter(this.filterPaths).length === 1 ? <>Learning Path</> : <>Learning Paths</>}</>
          }
        </div>
        {
          this.state.fetched ?
            <Grid container spacing={3}>
              {
                sortedPaths.filter(this.filterPaths).map((path, index) => {

                  let pathCourses = path.courses.filter(course => !course.attributes.field_archive)
                  let userPath = path.userLearningPath
                  let coursesCompleted = userPath && userPath.relationships.field_courses_completed.data.length
                  let isCompleted = userPath && userPath.attributes.field_status === 'completed'
                  let isInProgress = userPath && userPath.attributes.field_status === 'in_progress'

                  return (
                    <Card
                      key={path.id}
                      className={classNames("widget learningPath", isCompleted && 'completed', isInProgress && 'inProgress')}
                      style={{ animationDelay: index * .05 + 's' }}>
                      <Link to={'learning-path/' + path.attributes.drupal_internal__id}>
                        <div className="topContent">
                          <div className={'widgetLabel'}>{path.type}
                          </div>
                          <div className="pathImage" style={{ backgroundImage: 'url("' + process.env.REACT_APP_API_URL + path.image.attributes.uri.url + '")' }} />
                        </div>
                        <div className="bottomContent">
                          <h2>{path.attributes.name}</h2>
                          {
                            userPath &&
                            <div className="timestamp flexRow">
                              {
                                isCompleted ?
                                  <span className="icon check"></span>
                                  :
                                  <span className="icon laptop"></span>
                              }
                              <span>
                                {
                                  isCompleted &&
                                  <>Completed {generateTimestamp(userPath.attributes.changed)}</>
                                }
                                {
                                  !isCompleted && coursesCompleted > 0 &&
                                  <>In Progress {generateTimestamp(userPath.attributes.changed)}</>
                                }
                                {
                                  !isCompleted && !coursesCompleted &&
                                  <>Started {generateTimestamp(userPath.attributes.changed)}</>
                                }
                              </span>
                            </div>
                          }
                          <SanitizedHTML html={path.attributes.field_description} />
                          {
                            path.categories &&
                            <ul className="categoryList">
                              {
                                path.categories.map((category) => {
                                  return (
                                    <li key={category.id}>
                                      <div className='category-icon-wrapper'>
                                        <span className={classNames('category-icon', getCategoryLabel(path.categories, category.id))}></span>
                                        {getCategoryLabel(path.categories, category.id)}
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          }

                          {
                            userPath && !isCompleted &&
                            <div className="pathStatus">
                              {coursesCompleted} / {pathCourses.length} Courses Completed
                        <div className="statusBarWrapper">
                                <div className="statusBar" style={{ width: path.percentCompleted + '%' }}>
                                  <div className="statusBarInner"></div>
                                </div>
                              </div>
                            </div>
                          }

                        </div>
                      </Link>
                    </Card>
                  )
                })
              }
            </Grid>
            :
            <Loading />
        }
        {
          !learningPaths.filter(this.filterPaths).length && !navbar.activeItem === 'achievements' &&
          <p>Whoops! It looks like no Learning Paths matched your criteria. Adjust your filters and try again.</p>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathsLayout);