
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { Link } from 'react-router'
import { Card, Button } from '@material-ui/core'

import { setSidebar } from '../actions'

const mapStateToProps = ({ courses, categories }, ownProps) => {
  return {
    courses: courses.courseData,
    categories
  }
}

const mapDispatchToProps = {
  setSidebar
}

class WidgetCourseList extends Component {

  handleClick = () => {
    const { targetPage, targetTab } = this.props.widget
    this.props.setSidebar(targetPage, targetTab)
  }

  getCourseFromAssessment = (assessment) => {
    if (this.props.courses) {
      return this.props.courses.find(course => assessment.relationships.field_course.data.id === course.id)
    }
  }

  generateMessage = (status) => {
    let message

    if (status === 'in_progress') {
      if (this.props.widget.assessments.length === 1) {
        message = "Pick up where you left off with this course in progress."
      }
      else {
        message = "Move forward with these courses you've already started."
      }
    }
    else if (status === 'saved_for_later') {
      if (this.props.widget.assessments.length === 1) {
        message = "Ready to start this course? Let's go!"
      }
      else {
        message = "You've picked out some good stuff, let's get to it!"
      }
    }

    return message
  }

  render() {

    const { label, assessments, targetRoute, targetTab } = this.props.widget

    return (
      <Card className="widget" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel success">{label}</div>
        <div className={classNames('widgetIcon icon', targetTab === 'saved_for_later' ? 'pin' : 'laptop')} />

        <p>{this.generateMessage(targetTab)}</p>

        <ul className="course-list-widget">
          {
            // Remove this slice, we should only provide up to 3 items anyway
            assessments && assessments.map((assessment) => {
              let course = this.getCourseFromAssessment(assessment)

              if (course) {
                let categoryId = course.relationships.field_category.data.id
                let category = this.props.categories.find(cat => cat.id === categoryId)
                let categoryLabel = category.attributes.name.toLowerCase()
                return (
                  <li key={assessment.id}>
                    <Link to={'course/' + course.attributes.drupal_internal__id}>
                      <div className='category-icon-wrapper'>
                        <span className={classNames('category-icon', categoryLabel)} />
                      </div>
                      {course.attributes.name}
                    </Link>
                  </li>
                )
              }
              else {
                return false
              }
            })
          }
        </ul>
        <footer>
          <Link onClick={this.handleClick} to={'/' + targetRoute}>
            <Button className="button small">View All</Button>
          </Link>
        </footer>

      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCourseList);