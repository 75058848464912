import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { changeNavbar, setSidebar, createUserLearningPath, getAssessmentsForCourses, deleteAssessment, collapseSidebar } from '../actions'
import { getCourses, getLearningPaths, getUserLearningPaths } from 'common/actions/course'
import ReactPlayer from 'react-player'
import SanitizedHTML from './SanitizedHTML'
import CourseListItem from './CourseListItem'
import { Button } from '@material-ui/core'
import classNames from 'classnames'
import { getCategoryLabel, isLearningPathFinished, isCourseCategoryExcluded } from '../functions'

import Sidebar from './Sidebar'
import Loading from './Loading'

const mapStateToProps = ({ session, sidebar, categories, assessments, courses, learningPaths }, ownProps) => {

  let activePath = learningPaths.paths.find(path => path.attributes.drupal_internal__id === Number(ownProps.params.pathId))

  let pathCourses = activePath && activePath.relationships.field_courses.data.map((pathCourse) => {
    return courses.courseData.find(course => course.id === pathCourse.id)
  }).filter(course => course && !course.attributes.field_archive)

  let pathCategories = pathCourses && Array.from(new Set(pathCourses.map(course => categories.find(category => category.id === course.relationships.field_category.data.id))))

  let userPath = activePath && session.userLearningPaths && session.userLearningPaths.find(
    userPath => userPath.relationships.field_learning_path.data.id === activePath.id
  )

  // Get the next course that isn't submitted, being graded, or complete
  let nextPathCourse = pathCourses && pathCourses.filter((course) => {
    let isCompleted = userPath && userPath.relationships.field_courses_completed.data.find(completedCourse => completedCourse.id === course.id)
    let courseAssessment = assessments.all.find(assessment => assessment.relationships.field_course.data.id === course.id)
    let assessmentStatus = courseAssessment && courseAssessment.attributes.field_status
    let isExcluded = session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)
    let isNext = !assessmentStatus || assessmentStatus === 'saved_for_later' || assessmentStatus === 'in_progress'

    return !isExcluded && !isCompleted && isNext
  })[0]

  let pathCoursesCompleted = pathCourses && pathCourses.filter((course) => {
    let assessment = assessments.all.find(assessment => assessment.relationships.field_course.data.id === course.id)
    return assessment && assessment.attributes.field_status === 'completed'
  })

  return {
    assessments,
    pathCoursesCompleted,
    courses,
    categories,
    session,
    userPath,
    pathCategories,
    pathCourses,
    nextPathCourse,
    activePath,
    learningPaths,
    sidebar
  }
}

const mapDispatchToProps = {
  getCourses,
  getAssessmentsForCourses,
  createUserLearningPath,
  getLearningPaths,
  getUserLearningPaths,
  changeNavbar,
  deleteAssessment,
  collapseSidebar,
  setSidebar
}

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.seek !== this.props.seek) {
      this.player.current.seekTo(this.props.seek, 'seconds')
    }
  }

  render() {
    return (
      <ReactPlayer
        ref={this.player}
        url={this.props.url}
        controls={true}
        playing={this.props.seek && true}
      />
    )
  }

}

class LearningPathLayout extends Component {

  state = {
    started: false,
    assessmentsFetched: false
  }

  async componentDidMount() {
    this.props.changeNavbar('learn')

    if (!isMobile || window.innerWidth > 667) {
      this.props.collapseSidebar(true)
    }

    if (!this.props.learningPaths.fetched) {
      await this.props.getLearningPaths()
    }

    let ids = this.props.activePath.relationships.field_courses.data.map(course => course.id)
    this.props.getCourses(this.props.session.trial, ids)

    if (!this.props.assessments.fetched) {
      await this.props.getAssessmentsForCourses(ids)
      this.setState({ assessmentsFetched: true })
    }
    else {
      this.setState({ assessmentsFetched: true })
    }

    if (!this.props.session.fetched.userLearningPaths) {
      this.props.getUserLearningPaths(this.props.session.user)
    }
    else {
      this.setState({ started: !!this.props.userPath })
    }
  }

  startLearningPath = async () => {
    this.setState({ started: true })

    let completedCourseIds = this.props.pathCoursesCompleted.map(course => course.id)
    await this.props.createUserLearningPath(this.props.session, this.props.activePath, completedCourseIds)
    this.props.getUserLearningPaths(this.props.session.user)
  }

  goBack = () => {
    this.props.router.goBack()
  }

  goToLearningPaths = () => {
    this.props.setSidebar('learn', 'learningPaths')
    this.props.router.push('/')
  }

  removeAssessment = async (id) => {
    await this.props.deleteAssessment(id, this.props.session)
  }

  generateMessage = (percentCompleted, coursesRemaining) => {
    let message

    if (percentCompleted === 0) {
      message = <><strong>Ready for action!</strong></>
    }
    if (percentCompleted > 0 && percentCompleted <= 15) {
      message = <><strong>You're off to a great start!</strong></>
    }
    else if (percentCompleted > 15 && percentCompleted <= 30) {
      message = <><strong>You're doing great!</strong></>
    }
    else if (percentCompleted > 30 && percentCompleted <= 40) {
      message = <><strong>Keep it up!</strong></>
    }
    else if (percentCompleted > 40 && percentCompleted < 50) {
      message = <><strong>You got this!</strong></>
    }
    else if (percentCompleted === 50) {
      message = <><strong>Whoa, we're halfway there!</strong></>
    }
    else if ((percentCompleted > 50 && percentCompleted <= 70)) {
      message = <><strong>You're on a roll!</strong></>
    }
    else if ((percentCompleted > 70 && percentCompleted <= 85)) {
      message = <><strong>You'll be finished in no time!</strong></>
    }
    else if (percentCompleted > 85 && percentCompleted > 100) {
      message = <><strong>Wow, you're almost done!</strong></>
    }
    else if (percentCompleted === 100) {
      message = <><strong>Way to go!</strong></>
    }
    if (coursesRemaining === 1) {
      message = <><strong>One more course to go!</strong></>
    }

    return message
  }

  render() {
    const { sidebar, session, assessments, categories, nextPathCourse, userPath, activePath, pathCategories, pathCoursesCompleted, pathCourses } = this.props
    const { started, assessmentsFetched } = this.state

    let percentComplete = pathCoursesCompleted ? pathCoursesCompleted.length / pathCourses.length * 100 : 0
    let nextCourseAssessment = nextPathCourse && assessments.all.find(assessment => assessment.relationships.field_course.data.id === nextPathCourse.id)
    let coursesRemaining = userPath && pathCourses.length - userPath.relationships.field_courses_completed.data.length
    let locked = !userPath && !started
    let showNextUp = pathCoursesCompleted && (started || userPath) && nextPathCourse && (!nextCourseAssessment || nextCourseAssessment.attributes.field_status !== 'completed')

    // Caculate total estimated time
    // let allEstimatedTime = pathCourses && pathCourses.map(course => course.attributes.field_course_estimated_time).filter(time => time)
    // let totalEstimatedTime = allEstimatedTime && allEstimatedTime.reduce((a, b) => a + b, 0)

    let description = activePath && activePath.attributes.field_learning_path_video_descri
    let timestamps = description && description.split('-').filter(item => !isNaN(Number(item[0])))
    let labels = description && description.split('-').filter(item => isNaN(Number(item[0])))

    // Convert to seconds
    let videoTimestamps = timestamps && timestamps.map((time, index) => {
      let minutesAsSeconds = Number(time.substr(0, time.indexOf(':'))) * 60
      let seconds = Number(time.substr(time.indexOf(':'), 3).replace(':', ''))
      return { label: labels[index + 1], seconds: minutesAsSeconds + seconds }
    })

    let sidebarContent = <div className="entity-meta">
      <Button className="backButton" onClick={this.goBack}>
        <span className="icon back"></span>
        <span className="buttonText">Back</span>
      </Button>
      {
        activePath &&
        <span style={{ backgroundImage: 'url(' + process.env.REACT_APP_API_URL + activePath.image.attributes.uri.url + ')' }} className='category-icon large learning-path-icon' />
      }

      <h3>Learning Path</h3>
      {
        !userPath &&
        <div className="courseCount">
          {
            pathCourses &&
            <><span>{pathCourses.length}</span>Micro-Courses</>
          }
        </div>
      }
      {
        (userPath || this.state.started) && pathCourses &&
        <div className={classNames('pathStatus')}>
          {
            assessmentsFetched &&
            <>
              <header>Your Progress</header>
              {pathCoursesCompleted.length ? pathCoursesCompleted.length : 0} / {pathCourses.length}
              {
                !sidebar.isCollapsed &&
                <> Courses Completed</>
              }
              <div className="statusBarWrapper">
                <div className="statusBar" style={{ width: percentComplete + '%' }}>
                  <div className="statusBarInner"></div>
                </div>
              </div>

              <p className="message">
                {this.generateMessage(percentComplete, coursesRemaining)}
              </p>
            </>
          }
        </div>
      }

      {/* {
        this.props.courses.fetched && totalEstimatedTime > 0 &&
        <>
          <header>Estimated Time</header>
          <div className="flexRow course-time">
            <span className="icon clock" />
            <span className="count">{convertEstimatedTime(totalEstimatedTime)}</span>
          </div>
        </>
      }
 */}
      {
        pathCategories &&
        <>
          <header>Categories Included</header>
          <ul className="learningPathCategories">
            {
              pathCategories.map((category) => {
                return (
                  <li key={category.id}>
                    <div className='category-icon-wrapper'>
                      <span className={classNames('category-icon', getCategoryLabel(pathCategories, category.id))}></span>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </>
      }
    </div>


    return (
      <>
        <Sidebar currentPage="course" content={sidebarContent} />
        {
          activePath && this.props.session.fetched.userLearningPaths ?
            <div className='page learning-path-single'>
              <div className="course-content">
                <section>
                  <h2>{activePath.attributes.name}</h2>
                  {
                    this.props.session.fetched.userLearningPaths && !userPath &&
                    <div className={classNames('actions', this.state.started && 'fade')}>
                      <Button onClick={this.startLearningPath} className='button large primary'>
                        <span className="icon learningPath white"></span>Start Learning Path
                    </Button>
                    </div>
                  }
                  <SanitizedHTML html={activePath.attributes.field_description} />
                </section>
                {
                  showNextUp &&
                  <section className="section-nextUp">
                    <h3>Next Up</h3>
                    <ul className="course-library course-list nextUp">
                      <CourseListItem
                        assessment={nextCourseAssessment}
                        course={nextPathCourse}
                        status={nextCourseAssessment && nextCourseAssessment.attributes.field_status}
                        category={categories.find(cat => cat.id === nextPathCourse.relationships.field_category.data.id)}
                        currentPage="learningPath"
                        removeAssessment={() => { this.removeAssessment(nextCourseAssessment.id) }}
                      />
                    </ul>
                  </section>
                }
                <section className="video-content">
                  {
                    activePath.attributes.field_learning_path_video &&
                    <>
                      <h3>What You'll Learn</h3>
                      <div className="videoWrapper">
                        <VideoPlayer
                          url={activePath.attributes.field_learning_path_video}
                          seek={this.state.videoSeek}
                        />
                      </div>
                      {
                        videoTimestamps &&
                        <ul>
                          {
                            videoTimestamps.map((timestamp, index) => {
                              return (
                                <li key={index}>
                                  <Button onClick={() => { this.setState({ videoSeek: timestamp.seconds }) }} className="button small">
                                    {timestamps[index]}
                                  </Button>
                                  {timestamp.label}
                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </>
                  }
                </section>
              </div>
              <h3>Courses Included</h3>
              {
                locked &&
                <p>
                  {
                    pathCoursesCompleted && pathCoursesCompleted.length > 0 &&
                    <>
                      {
                        percentComplete === 100 ?
                          <><strong>Wow, you've already completed all the courses in this learning path! </strong>Click <strong>Start Learning Path</strong> to instantly get credit for completing it.</>
                          :
                          <><strong>You've already completed {pathCoursesCompleted.length} of these courses. </strong>Start this Learning Path to unlock and track your progress!</>
                      }
                    </>
                  }
                </p>
              }
              {
                !locked && isLearningPathFinished(pathCourses, assessments) &&
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <strong>Wow, you've submitted or completed all of the courses in this learning path!</strong> <Button onClick={this.goToLearningPaths} className="button small">Find More Learning Paths</Button>
                </div>
              }
              <ul className={classNames('course-library course-list', locked && 'disabled')}>
                {
                  pathCourses.map((course, index) => {

                    let assessment = assessments.all.find(assessment => assessment.relationships.field_course.data.id === course.id)
                    let categoryId = course.relationships.field_category.data.id
                    let category = categories.find(cat => cat.id === categoryId)
                    let hasComments = assessment && assessment.attributes.field_status === 'in_progress' && assessment.attributes.field_comments
                    let isExcluded = session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)

                    return (
                      <div key={course.id} className={classNames('courseWrapper', assessment && assessment.attributes.field_status === 'completed' && 'isCompleted', !assessment && 'unstarted', isExcluded && 'isExcluded')}>
                        <div className='courseIndex'>
                          <span className={classNames('status icon', hasComments && 'hasComments', assessment && assessment.attributes.field_status)} />
                        </div>
                        <CourseListItem
                          assessment={assessment}
                          course={course}
                          category={category}
                          status={assessment && assessment.attributes.field_status}
                          currentPage="learningPath"
                          removeAssessment={() => { this.removeAssessment(assessment.id) }}
                          isExcluded={isExcluded}
                        />
                      </div>
                    )
                  })
                }
              </ul>
            </div>
            :
            <Loading />
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathLayout);