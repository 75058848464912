import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { submitNPSSurvey } from '../actions'
import moment from 'moment'

import { RadioGroup, Radio, TextField, Button, FormControlLabel } from '@material-ui/core'

const mapStateToProps = ({ session }) => {
  return {
    session
  }  
}
const mapDispatchToProps = {
  submitNPSSurvey
}

class NPSSurvey extends React.Component {
  state = {
    isScored: false,
    score: null,
    comments: ''
  }

  handleChange = event => {
    this.setState({ score: Number(event.target.value) })
  }

  changeComments = event => {
    this.setState({ comments: event.target.value })
  }

  submit = () => {
    const { score, comments } = this.state
    const { user } = this.props.session

    this.setState({ isSubmitted: true })

    this.props.submitNPSSurvey({
      name: user.attributes.name + ' on ' + moment().format('MM/DD/YYYY'),
      field_nps_score: score,
      field_nps_improvement_general: score <= 6 ? comments : '',
      field_nps_improvement_specific: (score > 6 && score < 9) ? comments : '',
      field_nps_favorite_feature: score > 8 ? comments : '',    
    })
  }

  render() {
    const { score, comments, isScored, isSubmitted, isDismissed } = this.state
    let prompt, message
    if (score <= 6) {
      prompt = 'What can we do better?'
      message = "Thanks for your feedback. We highly value all ideas and suggestions from our customers, whether they’re positive or critical. In the future, our team might reach out to you to learn more about how we can further improve MobileMind so that it exceeds your expectations."
    }
    else if (score > 6 && score < 9) {
      prompt = "What's one thing we could do better?"
      message = "Thanks for your feedback. Our goal is to create the best possible product, and your thoughts, ideas, and suggestions play a major role in helping us identify opportunities to improve."
    }
    else {
      prompt = 'What is your favorite feature?'
      message = "Thanks for your feedback. It’s great to hear that you’re a fan of MobileMind. Your feedback helps us discover new opportunities to improve our product and make sure you have the best possible experience."
    }

    if (isDismissed) {
      return false
    }

    return (
      <div className="notification-peek">
        <Button onClick={() => { this.setState({ isDismissed: true })}} className="close">Close</Button>
        {
          !isScored &&
          <>
            <p>How likely are you to recommend MobileMind to a friend?</p>
            <RadioGroup aria-label="NPSScore" name="NPSScore" value={score} onChange={this.handleChange}>
              {
                [1,2,3,4,5,6,7,8,9,10].map((value) => {
                  return (
                    <FormControlLabel key={value} className={classNames({'active': value === score})} value={value} control={<Radio />} label={value} />
                  )
                })
              }
            </RadioGroup>
            <footer>
              { score && <Button className="button small" onClick={() => {this.setState({ isScored: true })}}>Continue</Button> }
            </footer>
          </>
        }
        {
          isScored && !isSubmitted &&
          <>
            <TextField
              id="outlined-multiline-flexible"
              label={prompt}
              multiline
              rowsMax="4"
              value={comments}
              onChange={this.changeComments}
              variant="outlined"
            />
            <footer>
              <Button className="button small" onClick={this.submit}>Submit</Button>
            </footer>
          </>
        }
        {
          isSubmitted &&
          <>
            <p>{message}</p>
            <footer>
              <Button className="button small" onClick={() => { this.setState({ isDismissed: true }) }}>Done</Button>
            </footer>
          </>
        }
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NPSSurvey);
