/* global chrome */
import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import { isChrome, isMobile } from 'react-device-detect'

import { generateTimestamp, getActiveUserLearningPath } from '../functions'
import { updateAssessment } from 'common/actions/course'

const mapStateToProps = ({ learningPaths, session, extension }, ownProps) => {

  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath = session.userLearningPaths && getActiveUserLearningPath(session.userLearningPaths, learningPaths, ownProps.course)

  return {
    activeUserLearningPath,
    learningPaths,
    session,
    extension
  }
}

const mapDispatchToProps = {
  updateAssessment
}

class CourseCard extends Component {

  state = {
    promptRemove: false
  }

  remove = (id) => {
    this.setState({ removed: true })
    this.props.removeAssessment(id)
  }

  resume = () => {
    !isMobile && isChrome && chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: 'startAssessment',
      assessmentId: this.props.assessment.id,
      activeCourse: this.props.course,
      learningPaths: this.props.learningPaths,
      userLearningPath: this.props.activeUserLearningPath
    })
  }

  render() {

    let { course, currentPage, category, secondaryCategories, status, badge, assessment } = this.props
    let { promptRemove, removed } = this.state

    let coursePath = "/course/" + course.attributes.drupal_internal__id
    let categoryLabel = category.attributes.name.toLowerCase()
    let level = course.attributes.field_level.toLowerCase()
    let isCompleted = status === 'completed'
    let isInProgress = status === 'in_progress'
    let isSaved = status === 'saved_for_later'
    let isSubmitted = status === 'grading_in_progress' || status === 'submitted'
    let feedback = assessment && assessment.attributes.field_comments
    let isRemovable = (!feedback && isInProgress)
    let timestamp = assessment && generateTimestamp(assessment.attributes.created)
    let updated = assessment && generateTimestamp(assessment.attributes.changed)

    if (assessment && feedback) {
      let length = assessment.attributes.field_comments.length
      if (length > 190) {
        feedback = '"' + assessment.attributes.field_comments.substring(0, 190) + '"...'
      }
    }

    return (
      <>
        {
          !removed &&
          <Grid item xs={3}>
            <Link to={coursePath}>
              <Card variant={isCompleted ? 'outlined' : 'elevation'} className={
                classNames(
                  'card small',
                  { 'isSaved': isSaved },
                  { 'notStarted': !status },
                  { 'hasFeedback': feedback && !isSubmitted },
                  { 'isCompleted': isCompleted }
                )}
                style={{ animationDelay: this.props.animationDelay }}
              >
                <div className="card-image" data-bg={categoryLabel} />
                <CardContent>
                  <header>
                    <div className='category-icon-wrapper'>
                      <span className={classNames('category-icon', categoryLabel)}></span>
                    </div>
                    <div className="course-label">
                      <span className="category-label">{categoryLabel}</span>
                      <div className="course-level">
                        <span className="icon star"></span>
                        {level === 'intermediate' && <span className="icon star"></span>}
                        {level === 'advanced' &&
                          <>
                            <span className="icon star"></span>
                            <span className="icon star"></span>
                          </>
                        }
                      </div>
                    </div>
                  </header>
                  <h2>{course.attributes.name}</h2>
                </CardContent>

                {
                  !assessment &&
                  <footer className="course-details">
                    <p>{course.attributes.field_course_objective}</p>
                    <footer>
                      {
                        secondaryCategories && secondaryCategories.length > 0 && secondaryCategories.map((category) => {
                          let categoryLabel = category.attributes.name.toLowerCase()
                          return (
                            <div key={category.id} className='category-icon-wrapper secondary'>
                              <span className={classNames('category-icon', categoryLabel)}></span>
                            </div>
                          )
                        })
                      }
                      {badge && <span className="icon badge"></span>}
                    </footer>
                  </footer>
                }
                {
                  isInProgress &&
                  <footer className={classNames('timestamp in-progress', { 'prompt': promptRemove })}>
                    {
                      feedback && !isSubmitted &&
                      <div className="timestamp-inner">
                        <strong>Feedback {updated}</strong>:
                        <p className="feedback">
                          <em>"{feedback}"</em>
                        </p>
                      </div>
                    }
                    {
                      !feedback && !isSubmitted &&
                      <div className="timestamp-inner">
                        Started {timestamp}
                      </div>
                    }
                  </footer>
                }
                {
                  isSaved &&
                  <footer className={classNames('timestamp saved')}>
                    {
                      this.state.isStartingFromSave ?
                        <>Starting Challenge...</>
                        :
                        <div className="timestamp-inner">
                          Saved {timestamp}
                        </div>
                    }
                  </footer>
                }
                {
                  isSubmitted &&
                  <footer className="timestamp in-progress">
                    Submitted {updated}
                  </footer>
                }
                {
                  isCompleted &&
                  <footer className="timestamp">
                    <div className="flexRow">
                      <span className="icon check"></span>Completed {updated}
                    </div>
                    {
                      feedback &&
                      <p className="feedback">
                        <em>"{feedback}"</em>
                      </p>
                    }
                  </footer>
                }
              </Card>
            </Link>
            {
              currentPage !== 'explore' && !this.state.isStartingFromSave &&
              <div className={classNames('actions', { 'removable': isRemovable }, { 'prompt': promptRemove })}>
                {
                  isSaved &&
                  <Button onClick={() => { this.remove(assessment.id) }} className="button small unsave">
                    <span className="icon pin" />Unsave
                  </Button>
                }
                {
                  isRemovable && !promptRemove &&
                  <Button
                    style={{
                      position: !this.props.extension.version && 'absolute',
                      right: !this.props.extension.version && 0,
                      top: !this.props.extension.version && -32
                    }}
                    onClick={() => this.setState({ promptRemove: true })}
                    className="button small remove">
                    <img src={require('../img/trash.svg')} alt="remove" />
                  </Button>
                }
                {
                  isInProgress && !promptRemove && this.props.extension.version &&
                  <Button onClick={this.resume} className="button small resume">Resume</Button>
                }
                {
                  promptRemove &&
                  <>
                    <p>Are you sure you want to restart this challenge?</p>
                    <div className="flexRow">
                      <Button onClick={() => { this.setState({ promptRemove: false }) }} className="button small">Cancel</Button>
                      <Button onClick={() => { this.remove(assessment.id) }} className="button small">Remove</Button>
                    </div>
                  </>
                }
              </div>
            }
          </Grid>
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard);