import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  TextField,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel
} from '@material-ui/core'

import _ from 'lodash'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import '../styles/sidebar.scss'
import { updateFilters } from '../actions'

const mapStateToProps = ({ session, categories, sidebar, learningPaths }, ownProps) => {

  let sortedCategories = _.sortBy(categories, (category) => {
    return category.attributes.name
  }).filter((category) => {
    // Filter out excluded categories if we have a group
    if (session.excludedCategories) {
      return !session.excludedCategories.find(cat => cat.target_id === category.attributes.drupal_internal__tid)
    }
    else {
      return category
    }
  })

  let filters = sidebar.pages.learn.items.find(item => item.id === 'explore').filters

  return {
    sortedCategories,
    filters,
    learningPaths: learningPaths.paths,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded
  }
}

const mapDispatchToProps = {
  updateFilters
}

class ExploreFilters extends Component {

  updateFilters = (event, key) => {
    let filters = { ...this.props.filters }
    let type = event.target.type

    filters[key] = type === 'checkbox' ? event.target.checked : event.target.value
    this.props.updateFilters(filters, 'explore')
  }

  resetFilters = () => {
    this.props.updateFilters({
      searchQuery: '',
      category: 'any',
      learningPath: 'any',
      remainingOnly: true
    }, 'explore')
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, 'mobile')
  }

  render() {

    const { searchQuery, category, learningPath, remainingOnly } = this.props.filters

    return (
      <form className={classNames('filters explore', this.props.sidebar.isCollapsed && 'collapsed', isMobile && this.props.isExpanded && 'expanded')}>
        <header>
          <strong>Filter Courses</strong>
          <Button className="button small" onClick={this.resetFilters}>Reset</Button>
        </header>
        <div className="flexRow inputSearch">
          <span className="icon search"/>
          <TextField 
            variant="standard"
            label="Search"
            value={searchQuery}
            onChange={(event) => { this.updateFilters(event, 'searchQuery') }}
          />
        </div>
       
        <FormControl className="inputSelect category">
          <span className="icon category"/>
          <InputLabel id="label-category-select">Category</InputLabel>
          <Select
            labelId="label-category-select"
            id="category-select"
            value={category}
            onChange={(event) => { this.updateFilters(event, 'category') }}>
            <MenuItem key={'any'} value={'any'}>Any</MenuItem>
            {
              this.props.sortedCategories && this.props.sortedCategories.map(category => {
                return (
                  <MenuItem key={category.id} value={category.id}>{category.attributes.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl className="inputSelect learningPath">
          <span className="icon learning-path"/>
          <InputLabel id="labelLearningPathSelect">Learning Paths</InputLabel>
          <Select
            labelId="labelLearningPathSelect"
            id="learningPathSelect"
            value={learningPath}
            onChange={(event) => { this.updateFilters(event, 'learningPath') }}>
            <MenuItem key={'any'} value={'any'}>Any</MenuItem>
            {
              this.props.learningPaths && this.props.learningPaths.map(path => {
                return (
                  <MenuItem key={path.id} value={path.id}>{path.attributes.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox checked={remainingOnly} onChange={(event) => { this.updateFilters(event, 'remainingOnly') }} />
          }
          label="Remaining Courses Only"
        />
        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon"/>
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreFilters);
