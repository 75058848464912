
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button } from '@material-ui/core'
import { Link } from 'react-router'
import { setSidebar } from '../actions'
// import { convertEstimatedTime } from '../functions'

const mapStateToProps = ({ session, courses, assessments }, ownProps) => {
  return {
    session
  }
}

const mapDispatchToProps = {
  setSidebar,
}

class WidgetAchievements extends Component {

  handleClick = () => {
    const { targetPage, targetTab } = this.props.widget
    this.props.setSidebar(targetPage, targetTab)
  }

  render() {
    const { userPicture } = this.props.session

    // Caculate total estimated time
    // let completedCourseIds = completedAssessments.map(assessment => assessment.relationships.field_course.data.id)
    // let allEstimatedTime = completedCourseIds && completedCourseIds.map((courseId) => {
    //   let course = courses.find(targetCourse => targetCourse.id === courseId)
    //   return course.attributes.field_course_estimated_time
    // }).filter(time => time)
    // let totalEstimatedTime = allEstimatedTime && allEstimatedTime.reduce((a, b) => a + b, 0)

    let userPictureSrc = userPicture ? process.env.REACT_APP_API_URL + userPicture.attributes.uri.url : require('../img/profile-pic.svg')

    return (
      <Card className="widget achievements" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel success">Way to Go!</div>

        <header className="flexRow">
          <div className="profilePicture" style={{ backgroundImage: 'url(' + userPictureSrc + ')' }}></div>
          <h2>Your Achievements</h2>
        </header>
        <div className="stats">
          {
            this.props.session.user.attributes.field_courses_completed > 0 &&
            <div className="flexRow">
              Courses Completed<span>{this.props.session.user.attributes.field_courses_completed}</span>
            </div>
          }
          {
            this.props.session.user.attributes.field_badges_earned > 0 &&
            <div className="flexRow">
              Badges Earned<span>{this.props.session.user.attributes.field_badges_earned}</span>
            </div>
          }
          {
            this.props.session.user.attributes.field_learning_path_completed > 0 &&
            <div className="flexRow">
              Learning Paths Completed<span>{this.props.session.user.attributes.field_learning_path_completed}</span>
            </div>
          }
          {/* {
            totalEstimatedTime > 0 &&
            <div className="flexRow">
              Estimated Time Spent
              <span>{convertEstimatedTime(totalEstimatedTime)}</span>
            </div>
          }
          {
            totalEstimatedTime > 0 &&
            <em>Time may count towards PD credit, depending on district policies.</em>
          } */}
        </div>
        <footer>
          <Link to='/achievements'>
            <Button className="button small">View Achievements</Button>
          </Link>
        </footer>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetAchievements);