import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { logout } from 'common/actions/user'
import classNames from 'classnames'
import moment from 'moment'
import { Menu, MenuItem } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { getNotifications, collapseSidebar, changeNavbar, setSidebar, getNPSSurvey, startProductTour } from '../actions'
import { updateProfile } from 'common/actions/user'
import NPSSurvey from './NPSSurvey'
import NotificationsMenu from './NotificationsMenu'
import '../styles/navbar.scss'

const mapStateToProps = ({ notifications, sidebar, announcements, session, extension, navbar }, ownProps) => {

  let surveyAnnouncement = announcements.data.find(announcement => announcement.relationships.field_survey.data)
  let showNewSurvey

  if (session.fetched.netPromoterScore) {
    // If we have a new survey announcement and they either have no NPS record, or it's from before this announcement
    let lastNPSSurvey = session.netPromoterScore
    showNewSurvey = surveyAnnouncement && (!lastNPSSurvey || moment(lastNPSSurvey.attributes.created).isBefore(surveyAnnouncement.attributes.created))
  }

  return {
    notifications,
    sidebar,
    announcements,
    session,
    extension,
    showNewSurvey,
    activeItem: navbar.activeItem
  }
}

const mapDispatchToProps = {
  getNotifications,
  changeNavbar,
  setSidebar,
  collapseSidebar,
  getNPSSurvey,
  startProductTour,
  updateProfile,
  logout
}

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  startProductTour = () => {
    this.setState({ anchorEl: null })
    this.props.collapseSidebar(true)
    this.props.startProductTour()
  }

  startOnboarding = async () => {
    this.props.updateProfile({
      name: this.props.session.user.attributes.field_name
    }, this.props.session)
  }

  render() {
    const { anchorEl } = this.state
    let isSales = this.props.session.roles.find(role => role.target_id === "mm_sales")

    return (
      <div>
        <div
          className="profilePicture"
          style={{ backgroundImage: 'url(' + this.props.profilePicSrc + ')' }}
          aria-owns={anchorEl ? 'user-menu' : undefined}
          onClick={this.handleClick}>
        </div>
        <Menu
          id="user-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem>
            <Link onClick={this.handleClose} to="/profile">My Profile</Link>
          </MenuItem>
          {
            !isMobile &&
            <MenuItem>
              <Link onClick={this.startProductTour} to="/">
                Start Product Tour
              </Link>
            </MenuItem>
          }

          {
            isSales &&
            <MenuItem>
              <Link onClick={this.startOnboarding} to="/">
                Start Onboarding
              </Link>
            </MenuItem>
          }

          <MenuItem onClick={this.handleClose}>
            <Link onClick={this.props.logout} to="/">Logout</Link>
          </MenuItem>
          {
            this.props.extension.version ?
              <footer>Extension v{this.props.extension.version}</footer>
              :
              <footer>No extension detected</footer>
          }
        </Menu>
      </div>
    )
  }
}

class AdminMenu extends React.Component {
  state = {
    anchorEl: null,
    active: false
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, active: true })
  }

  handleClose = () => {
    this.setState({ anchorEl: null, active: false })
  }

  render() {
    const { anchorEl } = this.state

    return (
      <li>
        <button className={classNames({ 'active': this.state.active })} onClick={this.handleClick}>Admin</button>
        <Menu id="admin-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={this.handleClose}>
            <a onClick={this.handleClose} target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + '/teachers'}>Teachers</a>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <a onClick={this.handleClose} target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + '/reports'}>Reports</a>
          </MenuItem>
        </Menu>
      </li>
    )
  }
}

class Navbar extends Component {

  componentDidMount() {
    this.props.getNotifications(this.props.session.user)
    this.props.getNPSSurvey(this.props.session.user)

    setInterval(() => {
      // Fetch user badges and notifications every 5 minutes
      this.props.getNotifications(this.props.session.user)
    }, 300000)
  }

  fetchNotifications = () => {
    this.props.getNotifications(this.props.session.user)
  }

  render() {
    const { session, showNewSurvey, activeItem } = this.props

    let profilePicSrc
    let adminRole = session.roles.find(role => role.target_id === 'administrator' || role.target_id === 'organization_admin')
    if (session.userPicture) {
      profilePicSrc = process.env.REACT_APP_API_URL + session.userPicture.attributes.uri.url
    }
    else {
      profilePicSrc = require('../img/profile-pic.svg')
    }

    let groupLabel
    if (session.group) {
      groupLabel = session.group.label[0].value
    }
    else if (session.user.attributes.field_subgroup) {
      groupLabel = session.user.attributes.field_subgroup
    }

    return (
      <div className="navbar">
        <nav>
          <h1 id="tour-mm-logo">
            <Link onClick={() => this.props.setSidebar('learn', 'dashboard')} to="/">
              <img className="logo" src={require('../img/logo-white.png')} alt="MobileMind" />
            </Link>
          </h1>

          <ul className="menu">
            <li className={classNames({ 'active': activeItem === 'learn' })}>
              <Link onClick={() => this.props.changeNavbar('learn')} to="/">Learn</Link>
            </li>
            <li id="tour-navbar-in-progress" className={classNames({ 'active': activeItem === 'in-progress' })}>
              <Link onClick={() => this.props.changeNavbar('in-progress')} to="/in-progress">In Progress</Link>
            </li>
            <li id="tour-navbar-achievements" className={classNames({ 'active': activeItem === 'achievements' })}>
              <Link onClick={() => this.props.changeNavbar('achievements')} to="/achievements">Achievements</Link>
            </li>

            {adminRole && <AdminMenu />}

          </ul>

          <div className="userInfo">
            <strong>{session.user.attributes.field_name}</strong>
            <span>
              {groupLabel}
              {
                session.trial &&
                <span className="trialText">Trial Version</span>
              }
            </span>
          </div>

          <UserMenu
            session={this.props.session}
            collapseSidebar={() => { this.props.collapseSidebar(true) }}
            extension={this.props.extension}
            startProductTour={() => { this.props.startProductTour('trigger') }}
            updateProfile={(profile, session) => { this.props.updateProfile(profile, session) }}
            profilePicSrc={profilePicSrc}
            logout={() => { this.props.logout() }}
          />
          <NotificationsMenu fetchNotifications={this.fetchNotifications} router={this.props.router} />
        </nav>
        {
          showNewSurvey &&
          <NPSSurvey />
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
