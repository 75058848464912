import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Masonry from 'react-masonry-component'

import WidgetCourseList from './WidgetCourseList'
import WidgetBadge from './WidgetBadge'
import WidgetAchievements from './WidgetAchievements'
import WidgetSocial from './WidgetSocial'
import WidgetSupport from './WidgetSupport'
import WidgetLearningPath from './WidgetLearningPath'
import WidgetAnnouncement from './WidgetAnnouncement'
import WidgetFeedback from './WidgetFeedback'
import Loading from './Loading'
import { startProductTour, getAnnouncements, getAssessmentsByStatus, getRecentUserBadge, getLearningPath, getUserLearningPathsByStatus } from '../actions'
import { isCourseCategoryExcluded } from '../functions'

import '../styles/dashboard.scss'

const mapStateToProps = ({ session, onboarding, courses, assessments, productTour, categories, learningPaths, announcements, sidebar }) => {

  let activeAnnouncements = announcements.data.filter((announcement) => {
    return announcement.relationships.field_announcement_type.data
  })

  let sortedAnnouncements = _.orderBy(activeAnnouncements, (announcement => announcement.attributes.created), ['desc'])

  let inProgressLearningPath = session.userLearningPaths.filter(userPath => userPath.attributes.field_status === 'in_progress')[0]

  let userBadges = session.userBadges && _.orderBy(session.userBadges, (badge) => {
    return badge.attributes.created
  }, ['desc'])

  let mostRecentUserBadge = userBadges && userBadges.length && userBadges[0]

  let inProgressWidget = {
    label: 'Jump Back In',
    type: 'course-list',
    targetRoute: 'in-progress',
    targetPage: 'inProgress',
    targetTab: 'in_progress',
    assessments: assessments.in_progress.slice(0, 3)
  }

  let savedForLaterWidget = {
    label: 'Ready for Launch',
    type: 'course-list',
    targetRoute: 'in-progress',
    targetPage: 'inProgress',
    targetTab: 'saved_for_later',
    assessments: assessments.saved_for_later.slice(0, 3)
  }

  let learningPathWidgetInProgress = {
    label: 'Next Steps',
    type: 'learningPath',
    status: 'in_progress',
    targetTab: 'learningPaths',
    userLearningPath: inProgressLearningPath
  }

  let badgeWidget = {
    label: 'Keep at it',
    type: 'badge',
    userBadge: mostRecentUserBadge
  }

  let socialWidget = { type: 'social' }

  let widgets = []

  // Important Announcements
  activeAnnouncements.forEach((announcement) => {
    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    if (type.attributes.name === "Important") {
      let image, video
      if (announcement.relationships.field_media.data) {
        image = announcements.images.find(image => image.id === announcement.relationships.field_media.data.id)
      }
      if (announcement.relationships.field_video.data) {
        video = announcements.videos.find(image => image.id === announcement.relationships.field_video.data.id)
      }
      let widget = {
        type: 'announcement',
        announcement: announcement,
        image: image && image,
        video: video && video,
      }
      widgets.push(widget)
    }
  })

  // Achievements
  if (session.user.attributes.field_courses_completed > 0) {
    widgets.push({ type: 'achievements' })
  }

  // Feedback Widget
  let assessment = assessments.in_progress.filter(assessment => assessment.attributes.field_comments)[0]
  let assessmentCourse = assessment && courses.courseData.find(course => course.id === assessment.relationships.field_course.data.id)
  if (assessmentCourse) {
    widgets.push({ type: 'feedback', assessment: assessment, assessmentCourse: assessmentCourse })
  }

  // Courses In Progress
  if (assessments.in_progress.length) {
    widgets.push(inProgressWidget)
  }

  // Saved Courses
  if (assessments.saved_for_later.length) {
    widgets.push(savedForLaterWidget)
  }

  // Current Learning Path Progress
  if (inProgressLearningPath) {
    widgets.push(learningPathWidgetInProgress)
  }

  // Most Recent Badge Awarded
  if (mostRecentUserBadge) {
    widgets.push(badgeWidget)
  }

  // Go through announcements
  activeAnnouncements.forEach((announcement) => {
    let image, video

    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    if (announcement.relationships.field_media.data) {
      image = announcements.images.find(image => image.id === announcement.relationships.field_media.data.id)
    }
    if (announcement.relationships.field_video.data) {
      video = announcements.videos.find(image => image.id === announcement.relationships.field_video.data.id)
    }

    // Hide Survey announcements for now
    let widget = type.attributes.name !== 'Survey' && {
      type: 'announcement',
      announcement: announcement,
      image: image && image,
      video: video && video,
    }

    // Only add Featured Courses if the teacher hasn't already started it, and if it's not an excluded category
    if (type.attributes.name === "Featured Course") {
      let assessmentForCourse = assessments.all.find(
        assessment => assessment.relationships.field_course.data.id === announcement.relationships.field_course.data.id
      )
      let course = courses.courseData && courses.courseData.find(course => course.id === announcement.relationships.field_course.data.id)
      let isExcluded = course && session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)

      if (!assessmentForCourse && !isExcluded) {
        widgets.push(widget)
      }
    }
    else if (type.attributes.name !== "Important") {
      widgets.push(widget)
    }
  })

  // Push TOTD announcements after all other announcements
  let tips = sortedAnnouncements.filter((announcement) => {
    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    return type.attributes.name === "Tip of the day"
  })

  tips.forEach((announcement) => {
    widgets.push({
      type: 'announcement',
      announcement: announcement
    })
  })

  // Social Widget
  widgets.push(socialWidget)

  // Support Widget
  widgets.push({ type: 'support' })

  return {
    session,
    widgets,
    announcements,
    learningPaths,
    productTour,
    sidebar,
    onboarding
  }
}

const mapDispatchToProps = {
  startProductTour,
  getAnnouncements,
  getAssessmentsByStatus,
  getUserLearningPathsByStatus,
  getRecentUserBadge,
  getLearningPath
}

class DashboardWidget extends Component {

  render() {

    const { type } = this.props.widget

    return (
      <div className="widgetWrapper">
        {
          type === 'course-list' &&
          <WidgetCourseList
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'feedback' &&
          <WidgetFeedback
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'learningPath' &&
          <WidgetLearningPath
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'badge' &&
          <WidgetBadge
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'achievements' &&
          <WidgetAchievements
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'announcement' &&
          <WidgetAnnouncement
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'social' &&
          <WidgetSocial
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'support' &&
          <WidgetSupport
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
      </div>
    )
  }
}

class DashboardLayout extends Component {
  state = {
    dashboardReady: false
  }

  prepareDashboard = () => {
    const { session } = this.props

    Promise.all([
      this.props.getAnnouncements(),
      this.props.getRecentUserBadge(),
      this.props.getAssessmentsByStatus('saved_for_later'),
      this.props.getAssessmentsByStatus('in_progress')
    ]).then(async () => {
      let userPathsInProgress = await this.props.getUserLearningPathsByStatus('in_progress')
      let inProgressLearningPathId = userPathsInProgress.length && userPathsInProgress[0].relationships.field_learning_path.data.id

      if (inProgressLearningPathId) {
        this.props.getLearningPath(inProgressLearningPathId)
      }

      this.setState({ dashboardReady: true })

      // Start the product tour if they haven't already
      if (!session.user.attributes.field_product_tour_started && !this.props.productTour.hasStarted) {
        this.props.startProductTour()
      }
    })

  }

  componentDidMount = async () => {
    if (this.props.session.user.jobTitle) {
      this.prepareDashboard()
    }
  }

  render() {
    const { widgets } = this.props
    const { dashboardReady } = this.state

    return (
      <div className="page dashboard">
        {
          dashboardReady ?
            <Masonry elementType={'ul'} options={{ transitionDuration: .3 }}>
              {
                widgets.map((widget, index) => {
                  if (widget) {
                    return (
                      <DashboardWidget
                        key={index}
                        widget={widget}
                        animationDelay={index * .05 + 's'}
                      />
                    )
                  }
                  else {
                    return false
                  }
                })
              }
            </Masonry>
            :
            <Loading message="Building your dashboard..." />
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);